import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import RaAppBar from '../../components/react-admin/overrides/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classnames from 'classnames';
import { Loadable } from '../../components/loadable';
import { usePermissions } from 'react-admin';
import { useCustomClaims } from '../../misc/use-custom-claims';

const AsyncLogo = import('../../components/svg/logo').then((mod) => mod.default['logoSecumailerOnly']);

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  icon: {
    width: '24px',
    height: '24px'
  },
  iconOpen: {
    fill: 'rgba(255,255,255,1)'
  },
  iconClosed: {
    fill: 'rgba(255,255,255,0.8)'
  }
});

export const AppBar = props => {
  const classes = useStyles();
  const claims = useCustomClaims()
  const permissions = usePermissions();
  const access = permissions.permissions;
  
  const titleAppendix = [
    access !== 'user' && access,
    claims.customer
  ].filter(Boolean).join(' - ');
    
  const renderMenuIcon = useMemo(() => {
    return ({ open }) => {
      const stateClass = open ? classes.iconOpen : classes.iconClosed;
      return <Loadable component={AsyncLogo} className={classnames(classes.icon, stateClass)} />;
    };
  }, [classes]);
  return (
    <RaAppBar {...props} renderMenuIcon={renderMenuIcon} color="primary">
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
      ><span id="react-admin-title"></span> - ({titleAppendix})</Typography>
      <span className={classes.spacer} />
    </RaAppBar>
  );
};