import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  root: {
    width: `400px`
  },
  item: {
    '& + &': {
      paddingTop: theme.spacing(2)
    }
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))