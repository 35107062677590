import { defaultTo, isNil } from 'ramda'
import { useState, useReducer, useEffect } from 'react'
import { produce } from 'immer'
import { SearchEventBounce } from '../../../../../packages/schema/search-event'
import { DeleteFromSuppressionListItem, DeleteFromSuppressionListResultItem, SuppressionListResult } from '../../../../../packages/schema/misc'
import { deleteFromSuppressionList, getBounceEvent } from '../../../components/react-admin/data-provider/resources/suppression-list'
import { enqueueSnackbar } from 'notistack'
import get from 'lodash/get'
import { compact } from 'lodash'


export interface ItemValid {
  event: SuppressionListResult
  status: `VALID`
}

export interface ItemVerifying {
  status: `VERIFYING`
}

export interface ItemFailed {
  error: string
  event: SuppressionListResult
  status: `FAILED`
}

export type SuppressionListItem = { email: string } & (ItemValid | ItemVerifying | ItemFailed)

export interface ListState {
  items: SuppressionListItem[]
  submitting: boolean
  selected: Set<string>
  deleteFailed: boolean
}

export type ListAction =
  { type: `ADD`, email: string } |
  { type: `REMOVE`, emails: string[] } |
  { type: `CLEAR` } | 
  { type: `VERIFIED`, email: string, valid: true, event: SuppressionListResult } |
  { type: `VERIFIED`, email: string, valid: false, error: string } | 
  { type: `DELETE_FAILED`, items: DeleteFromSuppressionListResultItem[] } |
  { type: `TOGGLE_SELECTED`, email: string } |
  { type: `TOGGLE_SELECT_ALL` }

export const INITIAL_STATE: ListState = { items: [], submitting: false, selected: new Set<string>(), deleteFailed: false }

const add = produce((draft: ListState, email: string) => {
  draft.items.push({ email, status: `VERIFYING` })
})

const setVerified = produce((draft: ListState, action: { email: string, } & ({ valid: true, event: SuppressionListResult } | { valid: false, error: string })) => {
  const idx = draft.items.findIndex(item => item.email === action.email)

  if (idx === -1) {
    return;
  }

  if (action.valid) {
    draft.items.splice(idx, 1, { email: action.email, status: `VALID`, event: action.event })  
  } else {
    draft.items.splice(idx, 1)  
  }
})

const remove = produce((draft: ListState, emails: string[]) => {
  draft.items = draft.items.filter(item => !emails.includes(item.email))
  emails.forEach(email => draft.selected.delete(email))
})

const deleteFailed = produce((draft: ListState, items: DeleteFromSuppressionListResultItem[]) => {
  const currentListMap = new Map(draft.items.map(item => [item.email, item]))

  const newList = compact(
    items.map(item => {
      const current = currentListMap.get(item.email)

      
      if (isNil(current) || current.status !== `VALID`) {
        return undefined
      }
      return {
        ...current,
        email: current.email,
        status: `FAILED`,
        error: item.error
      } satisfies SuppressionListItem
    })
  )
  draft.deleteFailed = true
  draft.items = newList
})

const toggleSelected = produce((draft: ListState, email: string) => {
  draft.selected = new Set(draft.selected)
  draft.selected.has(email) ? draft.selected.delete(email) : draft.selected.add(email)
})

const toggleSelectAll = produce((draft: ListState) => {
  if (draft.selected.size > 0) {
    draft.selected = new Set()
  } else {
    draft.selected = new Set(draft.items.map(item => item.email))
  }
})

export const useSuppressionList = () => {
  const [deleteState, setDeleteState] = useState<{ 
    items: DeleteFromSuppressionListItem[], busy: boolean,
  }>({
    items: [],
    busy: false,
  })

  const [lastEmail, setLastEmail] = useState<string | null>(null)
  const [suppressionListState, sendAction] = useReducer((state: ListState, action: ListAction) => {
    switch (action.type) {
      case `ADD`: 
        return add(state, action.email)
      case `VERIFIED`:
        return setVerified(state, action)
      case `REMOVE`:
        return remove(state, action.emails)
      case `CLEAR`:
        return INITIAL_STATE
      case `DELETE_FAILED`:
        return deleteFailed(state, action.items)
      case `TOGGLE_SELECTED`:
        return toggleSelected(state, action.email)
      case `TOGGLE_SELECT_ALL`:
        return toggleSelectAll(state)
    }
  }, INITIAL_STATE)


  useEffect(() => {
    if (!isNil(lastEmail)) {
      getBounceEvent(lastEmail)
        .then((data) => {
          sendAction({ type: `VERIFIED`, email: lastEmail!, valid: true, event: data })
        })
        .catch((error) => {
          sendAction({ type: `VERIFIED`, email: lastEmail!, valid: false, error: error.message })
          const msg = defaultTo(
            error.message,
            get(error, [`graphQLErrors`, 0, `message`])
          )
          enqueueSnackbar(<>
            Can't add {lastEmail} to the batch:<br/>
            { msg }
          </>, { variant: `error`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
        })

      setLastEmail(null)
    }
  }, [lastEmail]);

  useEffect(() => {
    if (deleteState.items.length > 0 && deleteState.busy) {
      deleteFromSuppressionList(deleteState.items)
        .then((result: DeleteFromSuppressionListResultItem[]) => {
          if (result.length > 0) {
            enqueueSnackbar(`${result.length} items couldn't be deleted`, { variant: `warning`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
            sendAction({ type: `DELETE_FAILED`, items: result })
          } else {
            enqueueSnackbar(`Addresses were successfully removed from suppression list`, { variant: `success`, autoHideDuration: 2000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
            setDeleteState({ items: [], busy: false })
            sendAction({ type: `CLEAR` })
          }
        })
    }
  }, [deleteState])

  return {
    state: suppressionListState,
    add: (email: string) => {
      sendAction({ type: `ADD`, email })
      setLastEmail(email)
    },
    deleteState,
    delete: () => setDeleteState({
      items: compact(suppressionListState.items
        .map(item => 
          item.status === `VALID` ?
            { 
              email: item.email,
              feedbackId: item.event.feedbackId,
              type: item.event.type
            }
            : undefined
        )),
      busy: true
    }),
    toggleSelected: (email: string) => {
      if (deleteState.busy) {
        return
      }
      sendAction({ type: `TOGGLE_SELECTED`, email })
    },
    toggleSelectAll: () => {
      if (deleteState.busy) {
        return
      }
      sendAction({ type: `TOGGLE_SELECT_ALL` })
    },
    reset: () => {
      setDeleteState({ items: [], busy: false })
      sendAction({ type: `CLEAR` })
    },
    deleteSelected: () => {
      sendAction({ type: `REMOVE`, emails: Array.from(suppressionListState.selected) })
    }
  }
}