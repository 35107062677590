export const colors = {
  set1: {
    purple: '#3B1C6E',
    purpleLight: '#ad4fc2',
    orange: '#FAB84D',
    white: '#F6FBFE'
  },
  set2: {
    black: 'black',
    gray: '#F6FBFE',
    darkGray: '#1C1C1A',
    white: 'white'
  }
};