import { makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useMemo } from 'react';
import { Route } from 'react-router';
import { TopToolbar, CreateButton, Button, Filter, TextInput, useNotify, Edit } from 'react-admin';
import { ListContextProvider, useListContext, useListController } from 'ra-core';
import { CreateAccount } from './create'
import Title from 'ra-ui-materialui/lib/layout/Title';
import { ListToolbar } from 'ra-ui-materialui';
import { useHistory } from 'react-router-dom';
import { CloudUpload } from '@material-ui/icons'
import { useAccountsListUpload } from './utils';
import { useDispatch } from 'react-redux';
import { AccountDetailsForm } from './form';
import { debounce } from 'lodash';
import { setBusy } from '../../../state/actions';

export const AccountsFilter = (props: any) => {
  return <Filter {...props}>
    <TextInput label="Address" source="search" autoComplete="off" resettable alwaysOn/>
    <Button label="Search" component="button"/>
  </Filter>;
}

const useStyles = makeStyles((theme) => {
  return {
    expandedView: {
      maxWidth: '100%',
      overflow: 'hidden'
    },
    table: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      tableLayout: 'fixed'
    },
    timestampField: {
      width: '150px'
    },
    eventTypeField: {
      width: '150px'
    },
    actionToolbar: {
      marginTop: theme.spacing(1)
    },
    phonenumberCol: {
      overflow: 'hidden'
    },
    addressCol: {
      width: '220px',
      overflow: 'hidden',
      wordWrap: 'break-word'
    },
    timestampCol: {
      width: '180px'
    },
    editCol: {
      width: '100px'
    }
  };
});

const AccountsListActions = ({ basePath, onUploadFile }: any) => {
  const styles = useStyles();
  return <div className={styles.actionToolbar}>
    <TopToolbar>
      <Button label="Upload XLS" component="label">
        <>
          <CloudUpload/>
          <input type="file" hidden onChange={onUploadFile} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
        </>
      </Button>
      &nbsp;
      <CreateButton basePath={basePath}><span>Create</span></CreateButton>
    </TopToolbar>
  </div>;
};

export const ViewAccount = (props: any) => {
  const listCtx = useListContext()
  if (!listCtx.filterValues.search) {
    return null
  }
  return <Edit {...props} id={listCtx.filterValues.search} undoable={false} title={<></>}>
    <AccountDetailsForm/>
  </Edit>
}

export const ListAccounts = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const notify = useNotify();

  const listContext = useListController({ ...props, syncWithLocation: false, debounce: 1500 })

  const goToAccount = useMemo(() => debounce((account: string) => {
    if (history.location.pathname === '/accounts') {
      history.push(`/accounts/${account}`)
    } else {
      history.replace(`/accounts/${account}`)
    }
  }, 300), [history])

  useEffect(() => {
    if (listContext.filterValues.search) {
      goToAccount(listContext.filterValues.search)
    } else {
      history.push(`/accounts`)
    }
  }, [listContext.filterValues.search])

  const [_uploadState, upload] = useAccountsListUpload('accounts-xls', (result) => {
    notify(`Added ${result.added} items, skipped ${result.skipped}`, 'info')
    dispatch(setBusy(false))
  })

  const handleClose = useCallback(() => {
    history.push('/accounts');
  }, [history]);

  const startUploadFile = useCallback((evt) => {
    dispatch(setBusy(true))
    upload(evt.target.files[0]);
  }, [upload]);

  return <>
    <ListContextProvider value={listContext}>
      <ListToolbar actions={<AccountsListActions basePath="/accounts" onUploadFile={startUploadFile}/>} filters={<AccountsFilter/>}/>
      <Title title="Accounts"/>
      <Route path="/accounts/:id">
        <ViewAccount {...props}/>
      </Route>
    </ListContextProvider>
    <Route path="/accounts/create">
        {({ match }) => (
          <CreateAccount open={!!match} onClose={handleClose} {...props}/>
        )}
    </Route>
  </>
};