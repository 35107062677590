const componentsContext = require.context('./', false, /\.tsx$/);

const components = componentsContext.keys().reduce((acc, key) => {
  const cmp = key.slice(2, -4);
  if (cmp === 'index') {
    return acc;
  }
  acc[cmp] = componentsContext(key).default;
  return acc;
}, {});


export { components };
