import { gql } from 'graphql-tag';
import { client } from '../client';
import { diffConfig } from './utils';

const configurationFieldsFragment = gql`
  fragment ConfigurationFields on MailboxConfiguration {
    customNdrMessages,
    insecureReportSchedule,
    organizationReportExcludeDelivered,
    organizationReportSchedule,
    personalReportSchedule,
    primaryAddress,
    pluginConfig {
      ttl,
      nta7516,
      addressCheck
    },
    domains {
      domain,
      configuration {
        digitalSignatureDomainExemption,
        digitalSignatureName,
        digitalSignatureText,
        dontUseDigitalSignature,
        dontUseIndividualReport,
        ignoreInternalEmail,
        ignoreSiblingDomain,
        insecureAuthRecipientAddressExemption,
        insecureAuthRecipientDomainExemption,
        insecureAuthSenderExemption,
        internalEmailAddressExemption,
        ntaCachePolicy,
        ntaExcludeRecipientDomains,
        ntaRecipientDomains,
        nta7516NotificationTitle,
        nta7516NotificationSubject,
        nta7516NotificationBody,
        portalAuth,
        portalBody,
        portalLogo,
        portalTitle,
        portalUrl,
        tlsDomainException,
        tlsHostnameException,
        useAttachmentLink,
        useAttachmentLink,
      }
    }
  }
`;

const getMailboxConfiguration = gql`
  query Configuration {
    mailboxConfiguration: getMailboxConfiguration {
      ...ConfigurationFields
    }
  }
  ${configurationFieldsFragment}
`;

const updateMailboxConfiguration = gql`
  mutation Configuration($configuration: MailboxConfigurationInput!) {
    mailboxConfiguration: updateMailboxConfiguration(configuration: $configuration) {
      ...ConfigurationFields
    }
  }
  ${configurationFieldsFragment}
`;

export const getOne = async () => {
  const { data: { mailboxConfiguration: configuration } } = await client.query<any>({ 
    query: getMailboxConfiguration,
    fetchPolicy: 'cache-first'
  });

  client.writeFragment({
    id: 'MailboxConfiguration',
    fragment: configurationFieldsFragment,
    data: configuration
  });

  return {
    data: {
      id: 'mailbox-configuration',
      ...configuration
    } 
  };
};

export const update = async ({ data: config }) => {
  delete config.id;

  const originalConfig = await client.readFragment({
    id: 'MailboxConfiguration',
    fragment: configurationFieldsFragment
  }) as any;


  const diffedConfig = diffConfig(originalConfig, config) as any;

  const { data: { mailboxConfiguration } } = await client.mutate<any>({ 
    mutation: updateMailboxConfiguration,
    optimisticResponse: false,
    variables: {
      configuration: diffedConfig
    }
  });

  client.writeFragment({
    id: 'MailboxConfiguration',
    fragment: configurationFieldsFragment,
    data: mailboxConfiguration
  });

  return {
    data: {
      id: 'mailbox-configuration',
      ...mailboxConfiguration
    }
  };
}