import classNames from 'classnames/bind';
import { type } from 'ramda';
import get from 'lodash/get';
import { useMemo, Fragment } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => {
  return {
    collapsed: {
      maxHeight: 'calc(1.5rem*5)',
      overflow: 'hidden'
    },
    wrapper: {
      wordWrap: 'break-word',
      whiteSpace: 'pre-line',
      lineHeight: '1.6rem',
      fontSize: '0.7rem',
      fontFamily: '"Roboto Mono", monospaced'
    },
    keyText: {
      wordWrap: 'break-word',
      whiteSpace: 'pre-line',
      display: 'inline-block',
      marginRight: '0.5em',
      padding: '0 4px',
      borderRadius: '2px',
      lineHeight: '1.4rem',
      backgroundColor: palette.grey[300],
    },
    valueText: {
      display: 'inline',
      wordBreak: 'break-all',
      whiteSpace: 'pre-line',
      lineHeight: '1.6em',
    },
  };
});

const keysPriority = [
  'commonHeaders.cc', 
  'commonHeaders.date', 
  'commonHeaders.from',
  'commonHeaders.messageId',
  'commonHeaders.replyTo',
  'commonHeaders.subject',
  'commonHeaders.to',
  'destination',
  'messageId',
  'source',
  'headers'
];

function representObjAsFlatList(obj: any, acc: Array<[string, string]> = [], parentKeys: Array<string> = []): Array<[string, string]> {
  if (type(obj) === 'Array') {
    return obj.reduce((acc: any, value: any) => representObjAsFlatList(value, acc, parentKeys), acc);
  }

  if (type(obj) === 'Object') {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      return representObjAsFlatList(value, acc, [...parentKeys, key]);
    }, acc);
  }

  const val: [string, string] = [parentKeys.join('.'), `${obj}`];
  
  

  return [
    ...acc,
    val
  ];

}


export const SourceViewField: React.ComponentType<any> = ({ record, source, expanded }: any) => {
  const styles = useStyles();
  const cx = classNames.bind(styles);

  const value = useMemo(() => {
    const flatList = representObjAsFlatList(get(record, source))
      .filter(([key]) => keysPriority.includes(key))
      .sort(([key1], [key2]) => {
        return keysPriority.indexOf(key1) - keysPriority.indexOf(key2);
      })
      .reduce((acc, [key, val]) => {
        if(acc[key]) {
          return {
            ...acc,
            [key]: [...acc[key], val]
          };
        } else {
          return {
            ...acc,
            [key]: [val]
          }
        }
      }, {} as {[key: string]: any});
      
    


    return Object.keys(flatList).map((key) => {
      return <Fragment key={key}>
        <span className={styles.keyText}>{key}</span>
        <span className={styles.valueText}>{flatList[key].join(', ')}</span>&nbsp;
      </Fragment>;
    });
  }, [record, source]);

  return <div className={cx('wrapper', { collapsed: !expanded })}>{value}</div>;
};