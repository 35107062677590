import { Auth } from 'aws-amplify';
import { AWSAppSyncClient } from 'aws-appsync';
import { InMemoryCache } from 'apollo-cache-inmemory';
import config from '../../../../aws-exports';

export const client = new AWSAppSyncClient({
  url: config.aws_appsync_graphqlEndpoint!,
  region: config.aws_appsync_region!,
  disableOffline: true,
  auth: {
    type: "AMAZON_COGNITO_USER_POOLS",
    jwtToken: async () => {
      const sess = await Auth.currentSession();
      return sess.getIdToken().getJwtToken();
    }
  }
}, { cache: new InMemoryCache({ addTypename: false }) as any });
