import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useReducer, ClipboardEvent } from 'react';
import { useInput, useNotify } from 'react-admin';
import { call, put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { runSaga, stdChannel } from 'redux-saga';
import { gql } from 'graphql-tag';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { client } from '../../../components/react-admin/data-provider/client';
import { checkImageUrl } from '../../../misc/check-image-url';

export interface FileUploadState {
  status: 'IDLE' | 'IN_PROGRESS' | 'FAILURE',
  progress?: number,
  url?: string,
  file?: any
}

const IMAGES_BUCKET_URL = process.env.SECUMAILER_ASSETS_URL!;
const PARSED_BUCKET_URL = new URL(IMAGES_BUCKET_URL);

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
    },
    image: {
      flex: '1 1 100%',
      paddingTop: '50%',
      marginRight: theme.spacing(2),
      backgroundPosition: 'left center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    },
    button: {
      flex: '0 0 120px',
      alignSelf: 'flex-start'
    },
    box: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexFlow: 'row nowrap'
    }
  };
});

const uploadUrlQuery = gql`
  query PresignedUrl($filename: String!, $type: String!) {
    getPresignedUploadUrl(filename: $filename, type: $type) {
      key, 
      url,
      fields
    }
  }
`;

const verifyUrl = (url: string, onError: (message: string) => void): string | null => {
  try {
    return checkImageUrl(url);
  } catch (e: any) {
    onError(e.message);
    return null;
  }
};

export const useFileUpload = (fileType: string, cb: (url) => void) => {
  const channel = useMemo(() => stdChannel(), []);

  const initialState: FileUploadState = {
    status: 'IDLE'
  };

  const reducer = (state: FileUploadState, action) => {
    switch (action.type) {
      case 'START': return {
        ...state,
        progress: 0,
        status: 'IN_PROGRESS',
        file: action.payload
      } as FileUploadState;
      case 'SUCCESS': return { ...state, status: 'IDLE', url: action.payload } as FileUploadState;
      case 'PROGRESS': return { ...state, progress: action.payload } as FileUploadState;
      case 'FAILED': return { ...state, status: 'FAILURE' } as FileUploadState
    }

    return state;
  };

  const [state, _dispatch] = useReducer(reducer, initialState);

  const dispatch = (action) => {
    _dispatch(action);
    channel.put(action);
  }

  useEffect(() => {
    const task = runSaga({ dispatch, channel }, function* () {
      yield takeEvery<AnyAction>('START', function* ({ payload }) {
        const result: any = yield call([client, 'query'], {
          query: uploadUrlQuery,
          variables: {
            filename: payload.name,
            type: fileType
          }
        });
        let { url, fields, key } = result.data.getPresignedUploadUrl;
        const data = new FormData();
        data.append('key', key);
        fields = JSON.parse(fields);
        Object.keys(fields).forEach((key) => data.append(key, fields[key]));
        data.append('file', payload);

        const requestPromise = axios({
          url,
          method: 'post',
          data,
          onUploadProgress: ({ total, loaded }) => {
            dispatch({ type: 'PROGRESS', payload: Math.ceil(loaded / total * 100) })
          }
        });

        yield requestPromise;
        let imagePathname = key.split('/').slice(1).join('/'); // We have to remove leading img/
        cb(imagePathname);
        yield put({ type: 'SUCCESS' });
      })
    });
    return () => task.cancel();
  }, []);

  return [state, (file) => dispatch({ type: 'START', payload: file })] as [FileUploadState, (file: File) => void];
};

const Image = ({ label, elements, getName, name, helperText, ...props }) => {
  if (getName) {
    name = getName(name);
  }

  const input = useInput({ ...props, source: name });
  const classes = useStyles();
  const notify = useNotify();

  const { input: { value, onChange } } = input;

  const [state, upload] = useFileUpload(name, (url) => onChange(url));

  const imageDivStyle = useMemo(() => {
    if (!value) {
      return {
        paddingTop: 0
      };
    }

    const url = new URL(
      [PARSED_BUCKET_URL.pathname, value].join('/'),
      PARSED_BUCKET_URL
    ).toString()
  
    return {
      backgroundImage: `url(${url})`
    };
  }, [value]);

  const startUploadFile = useCallback((evt) => {
    upload(evt.target.files[0]);
  }, [upload]);

  const onInputPaste = useCallback((evt: ClipboardEvent<any>) => {
    let paste = (evt.clipboardData || (window as any).clipboardData).getData('text');
    let url = verifyUrl(paste, (errorMessage) => notify(errorMessage, 'error'));
    evt.preventDefault();
    if (url) {
      onChange(url);
    }
  }, []);

  const pasteFromButton = useCallback(() => {
    navigator.clipboard.readText().then((paste: string) => {
      let url = verifyUrl(paste, (errorMessage) => notify(errorMessage, 'error'));
      if (url) {
        onChange(url);
      }
    });
  }, []);

  const pasteButtonEndAdornment = <InputAdornment position="end">
    <Button onClick={pasteFromButton}>
      Paste
    </Button>
  </InputAdornment>;

  return <Grid container direction="row" className={classes.wrapper}>
    <Grid item xs={12}>
      <TextField 
        variant="filled" 
        label={label} 
        helperText={helperText} 
        fullWidth={true} 
        value={value} 
        onPaste={onInputPaste}
        onChange={onChange}
        InputProps={{ endAdornment: pasteButtonEndAdornment}}
      />
    </Grid>
    <Grid item xs={12}>
      <Box className={classes.box}>
        <div style={imageDivStyle} className={classes.image} />
        <Button variant="contained" size="small" component="label" className={classes.button}>
          Upload File
          <input type="file" hidden onChange={startUploadFile} accept="image/*" />
        </Button>
      </Box>
    </Grid>
    {state.status === 'IN_PROGRESS' && <Grid item xs={12}>
      <LinearProgress variant="determinate" value={state.progress} />
    </Grid>}
  </Grid>;
}

export default Image;