const { parse } = require('graphql');
import { client } from '../client';

const searchApi = parse(/* GraphQL */`
  query SearchEvents($from: Int, $size: Int, $search: String, $domains: [String!], $startDate: AWSDateTime, $endDate: AWSDateTime, $sort: String) {
    searchEvents(from: $from, size: $size, search: $search, domains: $domains, startDate: $startDate, endDate: $endDate, sort: $sort) {
      total,
      items {
        id,
        eventType,
        fromDomain,
        source
      }
    }
  }
`);

export const getList = async ({pagination:{ page, perPage }, filter: { search, from, to, domain }, sort}) => {
  const { data: { searchEvents } } = await client.query<any>({ 
    query: searchApi,
    fetchPolicy: 'no-cache',
    variables: {
      from: perPage * (page - 1),
      size: perPage,
      search,
      startDate: from,
      endDate: to,
      domains: domain ? [domain] : undefined,
      sort: sort?.order.toLowerCase()
    }
  });

  return {
    data: searchEvents.items.map(({ source, ...rest }: any) => {
      const sourceParsed = JSON.parse(source);

      /**
       * Bounce and delivery event details
       * reside oustide mail object which
       * we display in the expanded UI, 
       * so we explicitely 
       * move them into mail object
       */

      if (sourceParsed.bounce) {
        sourceParsed.mail = {
          ...sourceParsed.mail,
          ...sourceParsed.bounce
        };
      }

      if(sourceParsed.delivery) {
        sourceParsed.mail = {
          ...sourceParsed.mail,
          delivery: sourceParsed.delivery
        };
      }

      sourceParsed.mail.timestamp = new Date(sourceParsed.mail.timestamp);
      return {
        ...rest,
        source: sourceParsed
      }
    }), // Each array item source is JSON encoded map
    total: searchEvents.total
  };
};