import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Create } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { AccountDetailsForm } from './form';

const useStyles = makeStyles(() => {
  return {
    title: {
      paddingBottom: 0
    }
  };
});

export const CreateAccount = ({ onClose, open, ...rest }) => {
  const classes = useStyles();
  return <Dialog onClose={onClose} open={open} maxWidth="md" container={() => document.getElementById('ra-modal-root')}>
    <DialogTitle classes={{ root: classes.title }}>Create new account</DialogTitle>
    <Create {...rest} title={<></>}>
      <AccountDetailsForm create/>
    </Create>
  </Dialog>;
};