import { Collapse, IconButton, Button, makeStyles, Paper, Table, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Toolbar, TextField, TableBody, Typography, Box, Checkbox, Tooltip } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import DeleteIcon from '@material-ui/icons/Delete'
import { SearchEventBounce } from '../../../../../packages/schema/search-event'
import { Title } from 'react-admin'
import { Field, Form } from 'react-final-form'
import { FormApi } from 'final-form'
import { useMemo } from 'react'
import { ExpandedViewField } from '../events/expanded-view'
import { SuppressionListItem, useSuppressionList } from './state'
import { all } from 'ramda'

export interface ItemValid {
  bounceEvent: SearchEventBounce
  status: `VALID`
}

export interface ItemVerifying {
  status: `VERIFYING`
}

export interface ItemInvalid {
  status: `INVALID`
}

export interface ListState {
  items: SuppressionListItem[]
  submitting: boolean
}

export type ListAction =
  { type: `ADD`, email: string } |
  { type: `REMOVE`, emails: string[] } |
  { type: `CLEAR` } | 
  { type: `VERIFIED`, email: string, valid: boolean }

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  expandedView: {
    maxWidth: '100%',
    overflow: 'hidden'
  },
  validText: {
    color: `green`
  },
  invalidText: {
    color: `red`
  }
});

const useFormStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  toolbar: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(3)
  },
  tableTitle: {
    flex: '1 1 100%'
  }
}))

export function Row(props: { data: any, selected: boolean, onChange: () => void }) {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();


  const added = useMemo(() => {
    if (data.status === `VALID`) {
      console.log(data.event)
      return new Date(data.event.event.timestamp).toLocaleString(`en-GB`)
    }

    return null;
  }, [data]);

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          { data.status === `VALID` &&
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Tooltip title="Select item to remove from the batch"> 
            <Box display="flex" alignItems="center">
              <Checkbox size="small" checked={props.selected} onChange={props.onChange} disabled={data.status === `FAILED`}/>
              &nbsp;
              {data.email}
            </Box>
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row">
          {
            data.status === `VALID` && data.event.type
          }
        </TableCell>
        <TableCell component="th" scope="row">
          {added}
        </TableCell>
        <TableCell align="right">
          { data.status === `VALID` ? 
              <span className={classes.validText}>Valid</span>
              : data.status === `VERIFYING` ? 
                <CircularProgress size={12} />
                : <span className={classes.invalidText}>{data.error}</span>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, wordWrap: `break-word` }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box m={1} className={classes.expandedView}>
              { 
                data.status === `VALID` ? 
                  <ExpandedViewField record={data} source="event"  /> 
                  : null 
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  ); 
}

export function EmailForm(props: { onEmailSubmitted: any }) {
  const css = useFormStyles()

  const submit = (values: any, form: FormApi) => {
    props.onEmailSubmitted(values.email)
    form.reset()
  }

  const disableAutocomplete = { "data-1p-ignore": true }

  return <Form onSubmit={submit} validateOnBlur={false}>
    {(props) =>      
        <form onSubmit={props.handleSubmit} noValidate>
          <Toolbar className={css.toolbar} disableGutters={true}>
            <Field name="email" type="search">
              {(props) =>
                <Tooltip title="Enter address you want to remove from suppression list"> 
                  <TextField {...props.input} label="Email" size="small" variant="standard" InputProps={{
                    endAdornment: <Button type="submit" color="default" size="small">Add</Button>,
                    autoComplete: "off",
                    ...disableAutocomplete
                  }}/>
                </Tooltip>
              }
            </Field>
          </Toolbar>
        </form>    
    }
  </Form>
}

export default function SuppressionList() {
  const suppressionList = useSuppressionList()
  const tableContent = suppressionList.state.items.length > 0 ? 
    suppressionList.state.items.map((data) => <Row data={data} onChange={() => suppressionList.toggleSelected(data.email)} selected={suppressionList.state.selected.has(data.email)} key={data.email}/>)
    : <TableRow>
        <TableCell colSpan={5}>
          <Typography variant="body2" color="textSecondary" align="center">Start adding addresses using form above</Typography>
        </TableCell> 
      </TableRow>
    
  const css = useFormStyles()
  const hasValidItems = suppressionList.state.items.some((item) => item.status === `VALID`)
  const allSelected = suppressionList.state.selected.size === suppressionList.state.items.length && suppressionList.state.items.length > 0
  const partiallySelected = suppressionList.state.selected.size > 0 && suppressionList.state.selected.size < suppressionList.state.items.length

  const allValid = all((item) => item.status === `VALID`, suppressionList.state.items)

  const submitButton = suppressionList.state.deleteFailed ?
    <Button variant="contained" color="default" onClick={suppressionList.reset}>Reset</Button> 
    : <Tooltip title="Remove items listed in the table from suppression list">
        <span>
          <Button color="primary" variant="contained" 
              disabled={!hasValidItems || suppressionList.deleteState.busy || !allValid}
              onClick={suppressionList.delete}>
                Delete
                {suppressionList.deleteState.busy && <CircularProgress size={24} style={{ marginLeft: `8px` }} />}
          </Button>
        </span>
      </Tooltip>

  return <>
    <Title title="Suppression list" />
    <EmailForm onEmailSubmitted={suppressionList.add}/>
    <Paper>
      <Toolbar className={css.header}>
        <Typography variant="h6" className={css.tableTitle}>Addresses to delete from suppression list</Typography>
        {
          suppressionList.state.selected.size > 0 && 
          <Tooltip title="Remove selected items from the batch">
            <IconButton onClick={suppressionList.deleteSelected}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        }
      </Toolbar>
      <TableContainer>
        <Table aria-label="Items to be removed from suppression list" style={{ tableLayout: `fixed` }} size="small">
          <TableHead>
            <TableRow>
              <TableCell width="48px" align="left">
              </TableCell>
              <TableCell align="left" padding="none">
                <Tooltip title="Select all / none">
                  <Box display="flex" alignItems="center">
                    <Checkbox size="small" checked={allSelected} indeterminate={partiallySelected} onChange={suppressionList.toggleSelectAll} disabled={suppressionList.state.deleteFailed}/>
                    Email
                  </Box>
                </Tooltip>
              </TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Added</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableContent}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    <Toolbar className={css.toolbar} disableGutters={true} variant="dense">
      {submitButton}
    </Toolbar>
  </>
}