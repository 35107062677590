import { TextInput } from 'react-admin';
import { makeStyles, StandardTextFieldProps } from '@material-ui/core';

const useStyles = makeStyles({
  input: {
    width: '100%'
  }
});

const textInputOptions: StandardTextFieldProps = {
  margin: 'none'
};

const Text = ({ name, getName, ...props }) => {
  const classnames = useStyles();
  if (getName) {
    name = getName(name);
  }
  return <TextInput source={name} className={classnames.input} {...props} options={textInputOptions}/>;
}

export default Text;