import React, { useMemo } from 'react';
import { IMaskInput } from 'react-imask';

import { TextInput } from 'react-admin';

function TextMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <IMaskInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.element : null);
      }}
      overwrite={true}
      autofix={true}
      onChange={undefined}
      onAccept={props.onChange}
    />
  );
}

export const MaskedTextInput = (props) => {
  const memoizedMaskComponent = useMemo(() => {
    return (cmpProps) => <TextMask mask={props.mask} unmask={true} {...cmpProps}/>
  }, [props.mask]);

  return <TextInput InputProps={{ inputComponent: memoizedMaskComponent }} {...props}/>;
}