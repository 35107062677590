import MenuItem from '@material-ui/core/MenuItem';
import { UserMenu } from 'ra-ui-materialui';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Cookie from 'js-cookie'
import { setActiveMailbox } from '../../../state/actions';
import { useCustomClaims } from '../../../misc/use-custom-claims';
import { useCallback } from 'react'

const useStyles = makeStyles(
  theme => ({
      root: {
          color: theme.palette.text.secondary,
      },
      active: {
          color: theme.palette.text.primary,
      },
      icon: { minWidth: theme.spacing(5) },
  }),
  { name: 'RaMenuItemLink' }
);

export const UserMenuView = (props) => {
  const classes = useStyles(props);
  const currentUserAttributes = useSelector((state: any) => state.userAttributes);
  const dispatch = useDispatch()
  const claims = useCustomClaims()

  const tryNewVersion = useCallback(() => {
    Cookie.set('new_version', 'true')
    window.location.href = '/'
  }, [])


  const renderMailboxMenuItem = (mailbox) => {
    return <MenuItem key={mailbox} selected={mailbox === claims.mailbox}
          classes={{ root: classes.root, selected: classes.active }}
          onMouseDown={() => dispatch(setActiveMailbox.request(mailbox))}>
          {mailbox}
    </MenuItem>
  };

  const selectMailboxItems = (claims.availableMailboxes || []).length > 0 ? 
    claims.availableMailboxes.map(renderMailboxMenuItem) :
    null

  return (
    <UserMenu label={currentUserAttributes.userName} {...props}>
      {selectMailboxItems}
      <MenuItem onMouseDown={tryNewVersion} divider>Try new portal version</MenuItem>
    </UserMenu>
  );
};