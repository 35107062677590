import { Edit, TabbedForm, FormTab, Toolbar, SaveButton, useQueryWithStore } from 'react-admin';
// @ts-ignore
import configurationMeta from './configuration.yml'; 
import { components } from './components';

export const CustomToolbar = (props) => {
  return <Toolbar {...props}>
    <SaveButton disabled={props.pristine}/>
  </Toolbar>;
}


export const EditMailbox = ({ staticContext, ...props }) => {
  const { data: configuration } = useQueryWithStore({ 
    type: 'getOne',
    resource: 'mailbox-configuration',
    payload: {}
  });

  let domainTabs = [];

  if (configuration) {
    domainTabs = configuration.domains.map(({ domain }) => domain);
  }

  props = {
    ...props,
    redirect: false,
    basePath: '/mailbox-configuration',
    resource: 'mailbox-configuration',
    id: 'mailbox-configuration',
    title: 'Configuration',
    actions: null
  };

  return <Edit {...props}>
    <TabbedForm toolbar={<CustomToolbar/>}>
      <FormTab label="summary">      
        {configurationMeta.common.map(({ component, ...rest }, idx) => {
          const Cmp = components[component];
          return <Cmp key={idx} {...rest}/>;
        })}
      </FormTab>
      {domainTabs.map((name, idx) => {
        const domainGetName = (attrName) => `domains[${idx}].configuration.${attrName}`;
        return <FormTab label={name} key={name}>
          {configurationMeta.domain.map(({ component, ...rest }, idx) => {
            const Cmp = components[component];
            return <Cmp key={idx} {...rest} getName={domainGetName}/>;
          })}
        </FormTab>;
      })}
    </TabbedForm>
  </Edit>;
};