import { Form } from 'react-final-form'
import { Card, CardHeader, CardContent, CircularProgress, CardActions, Button } from '@material-ui/core'
import { ValidateFunction } from '../../../misc/validation'
import { styles } from './styles'
import { useCallback, useRef } from 'react';

export default function AuthenticationForm<V>(
  props: { 
    onSubmit: (values: V) => Promise<any>; 
    title: string; 
    submitText: string; 
    validate: ValidateFunction; 
    initialValues?: Partial<V>,
    children: (props: { submitting: boolean }) => JSX.Element
  }
) {
  const formRef = useRef<HTMLFormElement>(null);
  const css = styles();

  return (
    <Form onSubmit={props.onSubmit} validate={props.validate} validateOnBlur={false} initialValues={props.initialValues}>
      {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate ref={formRef}>
            <Card classes={{ root: css.root }}>
              <CardHeader title="Sign in"/>
              <CardContent>
                {props.children({ submitting })}
              </CardContent>
              <CardActions classes={{ root: css.actions }}>
                <Button color="primary" type="submit" disabled={submitting} size="large" endIcon={submitting ? <CircularProgress size={24} /> : null} >
                  { props.submitText }
                </Button>
              </CardActions>
            </Card>
          </form>
      )}
    </Form>
  )
}