import { Field, Form } from 'react-final-form'
import { FormProps } from './types'
import * as YUP from 'yup'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Link, TextField as MuiTextField, CircularProgress } from '@material-ui/core'
import { useValidation } from '../../../misc/validation'
import { makeStyles } from '@material-ui/core'
import * as state from '../state'
import { styles } from './styles'
import wrapField from './form-field'

export const formValidation = YUP.object({
  password: YUP.string().required(`Please enter new password`),
  confirmPassword: YUP.string().required(`Please confirm your password`).equals([YUP.ref('password')], 'Passwords do not match!')
})

const TextField = wrapField(MuiTextField)

export default function ChangePassword(props: FormProps<state.ChangePassword>) {
  const validate = useValidation(formValidation);

  const css = styles();

  return <>
    <Form onSubmit={props.onSubmit} validate={validate} validateOnBlur>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate action="" method="POST">
          <input type="hidden" name="name" autoComplete="username" value={props.state.user.username}/>
          <Card classes={{ root: css.root }}>
            <CardHeader title="Set new password"/>
            <CardContent>
                <Grid container>                  
                  <Grid item xs={12} className={css.item}>
                    <Field name="password" type="password">
                      {(field) => <TextField {...field} fullWidth label="Your new password" autoComplete="new-password"/>}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={css.item}>
                    <Field name="confirmPassword" type="password">
                      {(field) => <TextField {...field} fullWidth label="Confirm password" autoComplete="new-password"/>}
                    </Field>
                  </Grid>
                </Grid>
            </CardContent>
            <CardActions classes={{ root: css.actions }}>
              <Button color="primary" type="submit" disabled={submitting}>
                { submitting ? <CircularProgress size={24} /> : `Set new password` }
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Form>
  </>;
}