import { makeStyles, StandardTextFieldProps } from '@material-ui/core';
import { NumberInput } from 'react-admin';

const useStyles = makeStyles({
  input: {
    width: '100%'
  }
});

const textInputOptions: StandardTextFieldProps = {
  margin: 'none'
};

const Number = ({ name, getName, ...props }) => {
  const classnames = useStyles();
  if (getName) {
    name = getName(name);
  }
  return <NumberInput source={name} className={classnames.input} {...props} options={textInputOptions}/>;
}

export default Number;