import * as R from 'ramda';
import { DataProvider } from 'ra-core';
import * as events from './resources/events';
import * as domains from './resources/domains';
import * as users from './resources/users';
import accounts from './resources/accounts';
import * as mailboxConfiguration from './resources/mailbox-configuration';
import * as suppressionList from './resources/suppression-list';

const mock = R.always(Promise.resolve(null)) as any;

const apis = {
  events,
  domains,
  accounts,
  users,
  'mailbox-configuration': mailboxConfiguration,
  'suppression-list': suppressionList
};

const getList = (entity, params) => apis[entity].getList(params);
const create = (entity, params) => apis[entity].create(params);
const update = (entity, params) => apis[entity].update(params);
const getOne = (entity, params) => apis[entity].getOne(params);
const updateMany = (entity, params) => apis[entity].updateMany(params);
const _delete = (entity, params) => apis[entity].delete(params);
const deleteMany = (entity, params) => apis[entity].deleteMany(params);


export const dataProvider: DataProvider = {
  getList,
  getOne,
  getMany: mock,
  getManyReference: mock,
  create,
  update,
  updateMany,
  delete:     _delete,
  deleteMany: deleteMany,
};