import { parse } from 'url';

const parsedSecumailerAssetsUrl = parse(process.env.SECUMAILER_ASSETS_URL!);

export function checkImageUrl(url: string) {
  const parsedUrl = new URL(url);

  if (!parsedUrl.hostname) {
    return normalizePath();
  }

  if (parsedUrl.hostname !== parsedSecumailerAssetsUrl.hostname) {
    throw new Error(`Only ${process.env.SECUMAILER_ASSETS_URL!} urls allowed`);
  }

  return normalizePath();

  function normalizePath() {
    const parts = parsedUrl.pathname.replace(/^\//, '').split('/');

    if (parts[0] === 'img') {
      parts.shift();
    }

    return parts.join('/');
  }
}