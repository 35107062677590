import { parse } from 'graphql';

import { uploadFile } from '../actions';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import { refreshView } from 'ra-core';

import { client } from '../../components/react-admin/data-provider/client';

const getActiveMailboxQuery = parse(/* GraphQL */`
  query GetActiveMailbox {
    getActiveMailbox {
      mailbox,
      impersonated,
      impersonatedCustomer
    }
  }
`);

const setActiveMailboxMutation = parse(/* GraphQL */`
  mutation SetActiveMailbox($mailbox: String) {
    setActiveMailbox(mailbox: $mailbox) {
      mailbox,
      impersonated,
      impersonatedCustomer
    }
  }
`);

function* uploadFileSaga() {
  yield takeEvery(uploadFile.request, function* (action) {
    // yield put(setBusy(true));
    // const { data: { setActiveMailbox: result } } = yield call([client, 'mutate'], {
    //   mutation: setActiveMailboxMutation,
    //   optimisticResponse: false,
    //   variables: {
    //     mailbox: action.payload
    //   }
    // });
    // yield put(setActiveMailbox.success(result));
    // yield call([client, 'resetStore'])
    // yield put(setBusy(false));
    
    // yield put(refreshView());
  });
}

export default [
  uploadFileSaga
];