import ChipList from './tag-list';

const checkEmail = (value) => {
  return value.indexOf('@') > -1;
}

const EmailTagList = (props) => {
  return <ChipList {...props} checkValue={checkEmail}/>; 
}

export default EmailTagList;