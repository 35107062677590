import { Field, Form } from 'react-final-form'
import { FormProps } from './types'
import * as YUP from 'yup'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Link, TextField, CircularProgress } from '@material-ui/core'
import { useValidation } from '../../../misc/validation'
import { makeStyles } from '@material-ui/core'
import * as state from '../state'
import { styles } from './styles'

export const formValidation = YUP.object({
  name: YUP.string().required(`Please enter your user name`),
})


export default function ForgotPassword(props: FormProps<state.ForgotPassword>) {
  const validate = useValidation(formValidation);

  const css = styles();

  return (
    <Form onSubmit={props.onSubmit} validate={validate} validateOnBlur>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Card classes={{ root: css.root }}>
            <CardHeader title="Forgot password"/>
            <CardContent>
                <Grid container>                  
                  <Grid item xs={12} classes={{ root: css.item }}>
                    <Field name="name">
                      {({ input, meta }) => <TextField {...input} error={meta.touched && meta.error} helperText={meta.touched && meta.error} fullWidth label="User name" autoComplete="off"/>}
                    </Field>
                  </Grid>
                </Grid>
            </CardContent>
            <CardActions classes={{ root: css.actions }}>
              <Button color="primary" type="submit" disabled={submitting} size='large'>
                { submitting ? <CircularProgress size={24} /> : `Reset password` }
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Form>
  );
}