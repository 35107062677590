import { equals, uniq } from 'ramda';

export const diffConfig = (config, updatedConfig) => {
  return diffObjects(config, updatedConfig, (valNew, _valOld, key) => {
    if (key === 'domains') {
      const diffedDomains = diffDomainsConfig()

      return Object.keys(diffedDomains).reduce((acc, key) => {
        return [
          ...acc,
          {
            domain: key,
            configuration: diffedDomains[key]
          }
        ]
      }, [] as any)
    }

    return valNew;
  });

  function diffObjects(obj1, obj2, cb?) {
    const keys = uniq([
      ...Object.keys(obj1),
      ...Object.keys(obj2)
    ]);  

    return keys.reduce((acc, key) => { 
      if (!equals(obj1[key], obj2[key])) {
        if (typeof obj2[key] !== 'undefined') {
          return {
            ...acc,
            [key]: cb ? cb(obj2[key], obj1[key], key) : obj2[key]
          };
        }
      }

      return acc;
    }, {});
  }

  function diffDomainsConfig() {
    const [domains, updatedDomains] = [config.domains, updatedConfig.domains].map((domainsConfig) => {
      return domainsConfig.reduce((acc, { domain, configuration }) => {
        return {
          ...acc,
          [domain]: configuration
        }
      }, {});
    });

    if (equals(domains, updatedDomains)) {
      return [];
    }

    const domainNames = uniq(Object.keys(domains).concat(Object.keys(updatedDomains)));

    return domainNames.reduce((acc, name) => {
      const domainCfg = diffObjects(domains[name], updatedDomains[name]);
      if (Object.keys(domainCfg).length === 0) {
        return acc;
      }

      return {
        ...acc,
        [name]: domainCfg
      };
    }, {});
  }
};