import { Admin, AdminProps, Resource } from 'react-admin';
import * as React from 'react';
import { render } from 'react-dom';
import Amplify, { Auth } from 'aws-amplify';
import { EventsList } from './features/resources/events';
import { authProvider } from './components/react-admin';
import awsConfig from '../aws-exports';
import { dataProvider } from './components/react-admin/data-provider';
import { secuMailerTheme } from './misc/theme';
import { AppLayout } from './containers/layout';
import { ListAccounts } from './features/resources/accounts';
import EventsIcon from '@material-ui/icons/ListAlt';
import AddressesIcon from '@material-ui/icons/People';
import { customRoutes } from './routes';
import { sagas } from './state/sagas';
import * as reducers from './state/reducers';
import { ListUsers } from './features/resources/users';
import { Authentication } from './features/authentication';
import { enableMapSet } from 'immer'

enableMapSet()

const { SnackbarProvider } = require('notistack');

window['LOG_LEVEL'] = 'DEBUG';

window['React'] = React;

Amplify.configure({
  Auth: {
    authenticationFlowType: 'CUSTOM_AUTH'
  },
  ...awsConfig
});

const adminProps: AdminProps = {
  disableTelemetry: true,
  theme: secuMailerTheme,
  loginPage: Authentication,
  layout: AppLayout as any,
  customReducers: reducers,
  customSagas: sagas,
  customRoutes: customRoutes,
  authProvider: authProvider,
  dataProvider: dataProvider
};


const App = () => {
  return <SnackbarProvider>
    <Admin {...adminProps}>
      {permissions => [
        <Resource name="events" list={EventsList} icon={EventsIcon} options={{ label: "Events" }}/>,
        <Resource name="accounts" list={ListAccounts} icon={AddressesIcon} options={{ label: "Accounts" }}/>,
        <Resource name="mailbox-configuration"/>,
        <Resource name="suppression-list"/>
      ].concat(permissions !== 'user' ? [
         <Resource name="users" options={{ label: "Users" }} list={ListUsers}/>
      ] : [])}
    </Admin>
  </SnackbarProvider>;
};

render(<App/>, window.document.getElementById('app'));
