import axios from 'axios';
import gql from 'graphql-tag';
import { useMemo, useReducer, useEffect } from 'react';
import { required, email, regex } from 'react-admin';
import { AnyAction } from 'redux';
import { stdChannel, runSaga } from 'redux-saga';
import { takeEvery, put, call } from 'redux-saga/effects';
import { client } from '../../../components/react-admin/data-provider/client';
import { FileUploadState } from '../../mailbox/components/image';

export const addressValidators = [required(), email()];
export const phonenumberValidators = [, regex(/\+\d{11}/, 'Invalid phone number')]
export const phonenumberMask = '{+}00000000000[00]';

const uploadUrlQuery = gql`
  query ImportUploadUrl {
    getMiscAssetUploadUrl {
      key
      url
      fields
    }
  }
`;

const bulkCreateAccountsMutation = gql`
  mutation BulkCreateAccounts($xlsUrl: String!) {
    bulkCreateAddresses(xlsUrl: $xlsUrl) {
      added
      skipped
    }
  }
`;

export const useAccountsListUpload = (fileType: string, cb: (url) => void) => {
  const channel = useMemo(() => stdChannel(), []);

  const initialState: FileUploadState = {
    status: 'IDLE'
  };

  const reducer = (state: FileUploadState, action) => {
    switch (action.type) {
      case 'START': return {
        ...state,
        progress: 0,
        status: 'IN_PROGRESS',
        file: action.payload
      } as FileUploadState;
      case 'SUCCESS': return { ...state, status: 'IDLE', url: action.payload } as FileUploadState;
      case 'PROGRESS': return { ...state, progress: action.payload } as FileUploadState;
      case 'FAILED': return { ...state, status: 'FAILURE' } as FileUploadState
    }

    return state;
  };

  const [state, _dispatch] = useReducer(reducer, initialState);

  const dispatch = (action) => {
    _dispatch(action);
    channel.put(action);
  }

  useEffect(() => {
    const task = runSaga({ dispatch, channel }, function* () {
      yield takeEvery<AnyAction>('START', function* ({ payload }) {
        const result: any = yield call([client, 'query'], {
          query: uploadUrlQuery,
          variables: {
            filename: payload.name,
            type: fileType
          }
        });
        let { url, fields, key } = result.data.getMiscAssetUploadUrl;
        const data = new FormData();
        data.append('key', key);
        fields = JSON.parse(fields);
        Object.keys(fields).forEach((key) => data.append(key, fields[key]));
        data.append('file', payload);

        const requestPromise = axios({
          url,
          method: 'post',
          data,
          onUploadProgress: ({ total, loaded }) => {
            dispatch({ type: 'PROGRESS', payload: Math.ceil(loaded / total * 100) })
          }
        });

        yield requestPromise;

        const { data: { bulkCreateAddresses } } = yield client.mutate({
          mutation: bulkCreateAccountsMutation,
          variables: {
            xlsUrl: key
          }
        })

        cb(bulkCreateAddresses);
        yield put({ type: 'SUCCESS' });
      })
    });
    return () => task.cancel();
  }, []);

  return [state, (file) => dispatch({ type: 'START', payload: file })] as [FileUploadState, (file: File) => void];
};