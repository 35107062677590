import { Grid, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { createElement, FC } from 'react'
import ChangePassword from './forms/change-password'
import ForgotPassword from './forms/forgot-password'
import ResetPassword from './forms/reset-password'
import SignIn from './forms/sign-in'
import TotpSetup from './forms/totp-setup'
import TotpVerify from './forms/totp-verify'
import { FormProps } from './forms/types'
import { useAuthenticationState, AuthenticationStep, AuthenticationState, Loading as LoadingState } from './state'

type A<Cmp extends FC<FormProps<any>>> = Cmp extends FC<FormProps<infer A>> ? 
  A extends AuthenticationState ? 
    A['step'] 
    : never
  : never

const styles = makeStyles(theme => ({
  root: {
    minHeight: `100vh`,
    backgroundColor: `
      background-color: ${theme.palette.primary.main};
      opacity: 0.8;
      background-image:  radial-gradient(${theme.palette.primary.dark} 0.5px, transparent 0.5px), radial-gradient(${theme.palette.primary.dark} 0.5px, ${theme.palette.grey[200]} 0.5px);
      background-size: 20px 20px;
      background-position: 0 0,10px 10px;
    `
  },
  alert: {
    display: `flex`
  },
  container: {
    display: `flex`,
    justifyContent: `center`,
    marginTop: theme.spacing(4)
  }
}))

const Loading = (props: FormProps<LoadingState>) => { 
  return <div>Authentication step is {props.state.step || ``}</div>
}

const Forms = {
  [AuthenticationStep.LOADING]: Loading,
  [AuthenticationStep.SIGN_IN]: SignIn,
  [AuthenticationStep.CHANGE_PASSWORD]: ChangePassword,
  [AuthenticationStep.TOTP_SETUP]: TotpSetup,
  [AuthenticationStep.TOTP_VERIFY]: TotpVerify,
  [AuthenticationStep.RESET_PASSWORD]: ResetPassword,
  [AuthenticationStep.FORGOT_PASSWORD]: ForgotPassword
} as const

const getFormComponent = (state: AuthenticationState, onSubmit: (values: any) => Promise<any>, forgotPassword: () => any) => {
  switch(state.step) {
    case(AuthenticationStep.CHANGE_PASSWORD): 
      return createElement(Forms[state.step], { state, onSubmit, forgotPassword })
    case(AuthenticationStep.SIGN_IN): 
      return createElement(Forms[state.step], { state, onSubmit, forgotPassword })
    case(AuthenticationStep.CHANGE_PASSWORD):
      return createElement(Forms[state.step], { state, onSubmit, forgotPassword })
    case(AuthenticationStep.TOTP_SETUP):
      return createElement(Forms[state.step], { state, onSubmit, forgotPassword })
    case(AuthenticationStep.TOTP_VERIFY):
      return createElement(Forms[state.step], { state, onSubmit, forgotPassword })
    case(AuthenticationStep.RESET_PASSWORD):
      return createElement(Forms[state.step], { state, onSubmit, forgotPassword })
    case(AuthenticationStep.FORGOT_PASSWORD):
      return createElement(Forms[state.step], { state, onSubmit, forgotPassword })  
    default:
      return null
  }
}

export const Authentication = () => {
  const [state, dispatch, submitStep, forgotPassword] = useAuthenticationState()
  const css = styles()

  return <Grid container className={css.root} justifyContent="center" alignContent="center">
    <Alert severity="warning" className={css.alert}>
        By accessing the logging information in this portal you may potentially<br/>
        get access to confidential and/or medical information transmitted<br/>
        via the subject line.<br/><br/>Please be aware of this when accessing the log data
    </Alert>
    <Grid item xs={12} className={css.container}>
      {
        getFormComponent(state, submitStep, forgotPassword)
      }
    </Grid>
  </Grid>;
}