// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2022-04-23T15:48:47.729Z

interface IAWSAmplifyFederatedConfiguration {
    google_client_id?: string;
    facebook_app_id?: string;
    amazon_client_id?: string;
}

interface IAWSAmplifyCloudLogicConfiguration {
    [index: number]: {
        endpoint: string;
        name: string;
        region: string;
    };
}

interface IAWSAmplifyConfiguration {
    aws_appsync_authenticationType?: string;
    aws_appsync_graphqlEndpoint?: string;
    aws_appsync_region?: string;
    aws_cognito_identity_pool_id?: string;
    aws_cognito_region?: string;
    aws_cloud_logic_custom?: IAWSAmplifyCloudLogicConfiguration;
    aws_project_region: string;
    aws_user_files_s3_bucket?: string;
    aws_user_files_s3_bucket_region?: string;
    aws_user_pools_id?: string;
    aws_user_pools_web_client_id?: string;
    aws_user_pools_web_client_secret?: string;
    federated?: IAWSAmplifyFederatedConfiguration;
}

const awsmobile: IAWSAmplifyConfiguration = {
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_graphqlEndpoint: process.env.GRAPHQL_API_URL,
    aws_appsync_region: process.env.REGION!,
    aws_cognito_region: process.env.REGION!,
    aws_project_region: process.env.REGION!,
    aws_user_files_s3_bucket: process.env.ASSETS_BUCKET_NAME!,
    aws_user_files_s3_bucket_region: process.env.REGION!,
    aws_user_pools_id: process.env.USER_POOL_ID!,
    aws_user_pools_web_client_id: process.env.USER_POOL_CLIENT_ID!,
};

export default awsmobile;
