import classNames from 'classnames/bind';
import get from 'lodash/get';
import { makeStyles, List, ListItem } from '@material-ui/core';

const useStyles = makeStyles(() => {
  return {
    item: {
      display: 'flex',
      '&:last-child': {
        borderBottom: 'none'
      }
    },
    title: {
      flex: '0 0 250px',
      fontSize: '0.7rem',
    },
    value: {
      fontFamily: '"Roboto Mono", monospaced',
      overflowX: 'auto',
      fontSize: '0.7rem',
    },
    valueJson: {
      fontFamily: '"Roboto Mono", monospaced',
      margin: '0'
    }
  };
});

const keysPriority = [
  'commonHeaders', 
  'destination',
  'messageId',
  'source',
  'headers'
];

const recursiveCreateItems = (value, cx, key) => {
  if (typeof value === 'string' || typeof value === 'number') {
    return <div className={cx('valueString')} key={key}>{value}</div>;
  } else if (value instanceof Date) {
    return <div className={cx('valueString')} key={key}>{value.toLocaleString()}</div>;
  } else if (value instanceof Array) {
    return value.map((v, idx) => recursiveCreateItems(v, cx, `${key}_${idx}`));
  } else {
    const json = JSON.stringify(value, null, 2).replace(/\\+"/g, '');

    return <pre className={cx('valueJson')} key={key}>{json}</pre>;
  }
}

export const ExpandedViewField: React.ComponentType<any> = ({ record, source }: any) => {
  const styles = useStyles();
  const cx = classNames.bind(styles);
  const obj = get(record, source);

  const sortedKeys = Object.keys(obj).sort(([key1], [key2]) => {
    return keysPriority.indexOf(key1) - keysPriority.indexOf(key2);
  });
    
  const items = sortedKeys.map((key) => {
    const value = obj[key];

    return <ListItem divider alignItems="flex-start" className={cx('item')} key={key}>
      <div className={cx('title')}>{key}</div>
      <div className={cx('value')}>{recursiveCreateItems(value, cx, key)}</div>
    </ListItem>;
  });


  return <List className={cx('wrapper')}>
    {items}
  </List>;
};