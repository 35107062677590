import ChipList from './tag-list';
import isValidDomain from 'is-valid-domain';

const checkDomainName = (value) => {
  return isValidDomain(value, { wildcard: true });
}

const DomainTagList = (props) => {
  return <ChipList {...props} checkValue={checkDomainName}/>; 
}


export default DomainTagList;