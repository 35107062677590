import { TextField } from '@material-ui/core';
import { ComponentProps, createElement, FC, useRef, useState } from 'react'
import { FieldInputProps, FieldMetaState } from 'react-final-form'

export type WrappedFieldProps = {
  component: React.FC<{ error: boolean; helperText: string } & FieldInputProps<any, HTMLElement>>
  input: FieldInputProps<any, HTMLElement>
  meta: FieldMetaState<any>
}

export default function wrapField<Cmp extends React.FC<{ error: boolean; helperText: string } & FieldInputProps<any, HTMLElement>>>(component: Cmp) {
  return function WrappedField(props: Omit<ComponentProps<Cmp>, `error` | `helperText`> & { input: FieldInputProps<any, HTMLElement>; meta: FieldMetaState<any> }) {
    const timeout = useRef<NodeJS.Timeout>()
    const [errorState, setErrorState] = useState<{ helperText: string; error: boolean }>({ helperText: '', error: false })

    const hasError = Boolean(props.meta.invalid && props.meta.touched);
    const helperText = hasError ? props.meta.error || props.meta.submitError : '';
    const { input, meta, ...rest } = props;

    const replaceTimeout = (newTimeout: NodeJS.Timeout) => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
      timeout.current = newTimeout
    }

    if (errorState.error !== hasError) {
      replaceTimeout(setTimeout(() => {
        setErrorState({ helperText, error: hasError })
      }, hasError ? 200 : 0))
    }

    return createElement(component, { ...rest, ...input, ...errorState })   
  }  
}