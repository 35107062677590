const { parse } = require('graphql');
import { client } from '../client';

const searchApi = parse(/* GraphQL */`
  query ListDomains {
    availableDomains
  }
`);

export const getList = async () => {
  const { data: { availableDomains } } = await client.query<any>({ 
    query: searchApi,
    fetchPolicy: 'cache-first'
  });

  return {
    data: availableDomains.map((domain) => ({ id: domain })),
    total: availableDomains.total
  };
};