import { Field, Form } from 'react-final-form'
import { FormProps } from './types'
import * as YUP from 'yup'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Link, TextField as MuiTextField, CircularProgress } from '@material-ui/core'
import { useValidation } from '../../../misc/validation'
import * as state from '../state'
import { styles } from './styles'
import FormField from './form-field'
import wrapField from './form-field'

export const formValidation = YUP.object({
  verificationCode: YUP.string().required(`Please enter confirmation code`),
  password: YUP.string().required(`Please enter new password`).min(8, `Password must be at least 8 characters long`),
})

const TextField = wrapField(MuiTextField)

export default function ResetPassword(props: FormProps<state.ResetPassword>) {
  const validate = useValidation(formValidation);

  const css = styles();

  return <>
    <Form onSubmit={props.onSubmit} validate={validate} validateOnBlur>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate action="" method="POST">
          <input type="hidden" name="name" autoComplete="username" value={props.state.userName}/>
          <Card classes={{ root: css.root }}>
            <CardHeader title="Password reset"/>
            <CardContent>
                <Grid container>                  
                  <Grid item xs={12} className={css.item}>
                    <Field name="verificationCode" type="text">
                      {({ input, meta }) => <TextField input={input} meta={meta} fullWidth label="Verification code" data-1p-ignore/>}
                      
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={css.item}>
                    <Field name="password" type="password">
                      {({ input, meta }) => {
                        return <TextField input={input} meta={meta} fullWidth label="Password" autoComplete="new-password"/>}
                      }
                    </Field>
                  </Grid>
                </Grid>
            </CardContent>
            <CardActions classes={{ root: css.actions }}>
              <Button color="primary" type="submit" disabled={submitting}>
                { submitting ? <CircularProgress size={24} /> : `Submit` }
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Form>
  </>;
}