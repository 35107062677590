import { createAction, createAsyncAction } from 'typesafe-actions';

export const setActiveMailbox = createAsyncAction(
  'PORTAL/UPDATE_ACTIVE_MAILBOX_REQUEST',
  'PORTAL/UPDATE_ACTIVE_MAILBOX_SUCCESS',
  'PORTAL/UPDATE_ACTIVE_MAILBOX_FAILURE',
)<string, { mailbox: string, impersonated: boolean, customer: string }, Error>();

export const loadUserAttributes = createAsyncAction(
  'PORTAL/LOAD_USER_ATTRS_REQUEST',
  'PORTAL/LOAD_USER_ATTRS_SUCCESS',
  'PORTAL/LOAD_USER_ATTRS_FAILURE',
)<unknown, unknown, Error>();

export const setBusy = createAction('PORTAL/SET_BUSY', (busy: boolean) => {
  return busy;
})();

export const uploadFile = createAsyncAction(
  'PORTAL/UPLOAD_FILE_REQUEST',
  'PORTAL/UPLOAD_FILE_SUCCESS',
  'PORTAL/UPLOAD_FILE_FAILURE'
)<any, any, Error>(); 