import { Grid, makeStyles } from '@material-ui/core';
import { components } from '.';
import GroupHeader from './group-header';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: theme.spacing(1)
  }
}));

const Group = ({ label, elements, getName, itemSize }) => {
  const classnames = useStyles();
  return <div>
    {label && <GroupHeader>
      {label}
    </GroupHeader>}
    <Grid container>
      {elements.map(({ component, ...rest }, idx) => {
        const Cmp = components[component];
        const size = rest.size || itemSize;

        return <Grid item className={classnames.item} {...size} key={idx}>
          <Cmp {...rest} getName={getName}/>
        </Grid>;
      })}
    </Grid>
  </div>;
}

export default Group;