import gql from 'graphql-tag';
import { client } from '../client';
import { DeleteFromSuppressionListItem } from '../../../../../../packages/schema/misc';

const suppressionEvent = gql`
  query SearchEvents($email: String!) {
    suppressionListEvent(email: $email) {
      feedbackId,
      type,
      event
    }
  }
`;


const deleteFromSuppressionListMutation = gql`
  mutation DeleteFromSuppressionList($items: [DeleteFromSuppressionListItem!]!) {
    deleteFromSuppressionList(items: $items) {
      email,
      error
    }
  }
`

export const deleteFromSuppressionList = async (items: DeleteFromSuppressionListItem[]) => {
  const { data } = await client.mutate<any>({
    mutation: deleteFromSuppressionListMutation,
    fetchPolicy: 'no-cache',
    variables: {
      items
    }
  })

  return data.deleteFromSuppressionList
}

export const getBounceEvent = async (email: string) => {
  const { data: { suppressionListEvent } } = await client.query<any>({ 
    query: suppressionEvent,
    fetchPolicy: 'no-cache',
    variables: {
      email
    }
  })

  return {
    ...suppressionListEvent,
    event: JSON.parse(suppressionListEvent.event)
  }
}