import { Field, Form } from 'react-final-form'
import { FormProps } from './types'
import * as YUP from 'yup'
import { QRCodeSVG } from 'qrcode.react'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Link, TextField, CircularProgress } from '@material-ui/core'
import { useValidation } from '../../../misc/validation'
import { makeStyles } from '@material-ui/core'
import * as state from '../state'
import { styles } from './styles'
import AuthenticationForm from './authentication-form'

export const formValidation = YUP.object({
  token: YUP.string().required(`Please enter token`)
})


export default function TotpVerify(props: FormProps<state.TotpVerify>) {
  const validate = useValidation(formValidation);

  const css = styles();

  return <AuthenticationForm onSubmit={props.onSubmit} submitText="Submit" title="TOTP verification" validate={validate}>
      {({ submitting }) => <>
          <input type="hidden" name="name" autoComplete="username" value={props.state.user.username}/>
          <Grid container>                  
            <Grid item xs={12}>
              <Field name="token" type="token">
                {({ input, meta }) => <TextField {...input} error={meta.touched && meta.error} helperText={meta.touched && meta.error} fullWidth label="Token" autoComplete="one-time-code" inputMode="numeric" autoFocus/>}
              </Field>
            </Grid>
          </Grid>
        </>
      }
    </AuthenticationForm>
}