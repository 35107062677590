import { Auth } from 'aws-amplify';
import { AuthProvider } from 'react-admin';
import { loadActiveMailbox } from '../../state/sagas/user-sagas';

const ADMIN_GROUP_ID = 'Administrators';
const RESELLER_GROUP_ID = 'Resellers';

const attrsForUser = (user) => {
  return {
    username: user.username,
      ...user.attributes
  };
};

export const authProvider: AuthProvider = {
  login: async (accessToken) => {
    return Promise.resolve(accessToken);
  },
  checkError: () => <any>Promise.resolve(false),
  logout: async () => Auth.signOut({ global: true }),
  checkAuth: () => {
    return Auth.currentAuthenticatedUser()
      .then(async () => {
        await loadActiveMailbox()
      })
  },
  getPermissions: () => {
    return Auth.currentAuthenticatedUser()
      .then(({ signInUserSession }) => {
        const groups = (signInUserSession.accessToken.payload['cognito:groups'] || [])

        const group = groups.find((group) => group === ADMIN_GROUP_ID || group === RESELLER_GROUP_ID);
        
        if (group === ADMIN_GROUP_ID) {
          return 'admin';
        } else if (group === RESELLER_GROUP_ID) {
          return 'reseller';
        } else {
          return 'user';
        }
      })
  }
}