import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

export const useCustomClaims = () => {
  const [currentUserInfo, setCurrentUserInfo] = useState<{ availableMailboxes: string[], mailbox: string, impersonated: boolean, customer: string }>({ mailbox: '', impersonated: false, customer: '', availableMailboxes: [] })
  const currentPoolUser = (Auth as any).userPool.getCurrentUser()
  const idToken = currentPoolUser.storage[
    `${currentPoolUser.keyPrefix}.${currentPoolUser.username}.idToken`
  ]

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(({ signInUserSession }) => {
      setCurrentUserInfo({
          mailbox: signInUserSession.idToken.payload['customer-portal:mailbox'],
          customer: signInUserSession.idToken.payload['customer-portal:customer'],
          impersonated: signInUserSession.idToken.payload['customer-portal:impersonated'] === 'true',
          availableMailboxes: signInUserSession.idToken.payload['custom:mailbox'].split(',')
      })
      return signInUserSession
    })
  }, [idToken]);

  return currentUserInfo
}