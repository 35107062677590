import { parse } from 'graphql'
import { HttpError } from 'react-admin';
import { client } from '../client';

const getApi = parse(/* GraphQL */`
  query GetAccount($address: String!) {
    getAccount(address: $address) {
      address,
      created,
      updated,
      auth,
      phonenumber
    }
  }
`);

const createApi = parse(/* GraphQL */`
  mutation CreateAccount($address: String!, $phonenumber: String) {
    createAccount(address: $address, phonenumber: $phonenumber) {
      address,
      created,
      updated,
      auth,
      phonenumber
    }
  }
`);

const updateApi = parse(/* GraphQL */`
  mutation UpdateAccount($address: String!, $phonenumber: String) {
    updateAccount(address: $address, phonenumber: $phonenumber) {
      address,
      created,
      updated,
      auth,
      phonenumber
    }
  }
`);

const deleteApi = parse(/* GraphQL */`
  mutation DeleteAccounts($addresses: [String!]!) {
    deleteAccounts(addresses: $addresses)
  }
`);

const apis = {
  getList: async () => {
    return {
      data: [],
      page: 0,
      total: 0
    }
  },
  create: async ({ data }) => {
    try {
      const { data: { createAccount: item } } = await client.mutate<any>({
        mutation: createApi,
        optimisticResponse: false,
        variables: data
      });  

      return {
        data: {
          ...item,
          id: item.address,
          auth: item.auth ? new Date(item.auth) : null,
          created: new Date(item.created),
          updated: new Date(item.updated)
        }
      };  
    } catch (e) {
      throw new HttpError(
        e.graphQLErrors[0].message,
        500
      );
    }
  },
  update: async ({ data }) => {
    try {
      const { data: { updateAccount: item } } = await client.mutate<any>({
        mutation: updateApi,
        optimisticResponse: false,
        variables: {
          address: data.address,
          phonenumber: data.phonenumber
        }
      });  

      return {
        data: {
          ...item,
          id: item.address,
          auth: item.auth ? new Date(item.auth) : null,
          created: new Date(item.created),
          updated: new Date(item.updated)
        }
      };  
    } catch (e) {
      throw new HttpError(
        e.graphQLErrors[0].message,
        500
      );
    }
  },
  deleteMany: async({ ids }) => {
    try {
      const { data: { deleteAccounts: deletedIds } } = await client.mutate<any>({
        mutation: deleteApi,
        optimisticResponse: false,
        variables: {
          addresses: ids
        }
      });  

      return {
        data: deletedIds
      };  
    } catch (e) {
      throw new HttpError(
        e.graphQLErrors[0].message,
        500
      );
    }
  },
  delete: async({ id }) => {
    try {
      await client.mutate<any>({
        mutation: deleteApi,
        optimisticResponse: false,
        variables: {
          addresses: [id]
        }
      });  

      return {
        data: [id]
      };  
    } catch (e) {
      throw new HttpError(
        e.graphQLErrors[0].message,
        500
      );
    }
  },
  getOne: async({ id: address }) => {
    try {
      const { data: { getAccount: item } } = await client.query<any>({ 
        query: getApi,
        fetchPolicy: 'no-cache',
        variables: {
          address
        }
      });
      
      console.log('Found', item)

      return {
        data: {
          ...item,
          id: item.address,
          auth: item.auth ? new Date(item.auth) : null,
          created: new Date(item.created),
          updated: new Date(item.updated)
        }
      };
    } catch (e) {
      console.log('Error', e)
      // e.graphQLErrors[0].message
      throw new HttpError(
        `Wow such failure`,
        500
      );
    }
  }
};

export default apis;