import { Field, Form } from 'react-final-form'
import { FormProps } from './types'
import * as YUP from 'yup'
import QRCode from 'react-qr-code'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Link, TextField, CircularProgress, Typography } from '@material-ui/core'
import { useValidation } from '../../../misc/validation'
import { makeStyles } from '@material-ui/core'
import * as state from '../state'
import { styles } from './styles'
import AuthenticationForm from './authentication-form'

export const formValidation = YUP.object({
  token: YUP.string().required(`Please enter token`)
})

const localStyles = makeStyles((theme) => ({
  qrCode: {
    display: 'flex',
    justifyContent: 'center'
  },
}))

export default function TotpSetup(props: FormProps<state.TotpSetup>) {
  const validate = useValidation(formValidation);

  const css = styles();
  const localCss = localStyles()

  return <>
    <AuthenticationForm onSubmit={props.onSubmit} submitText="Submit" title="TOTP setup" validate={validate}>
        {({ submitting }) => 
          <>
            <input type="hidden" name="name" autoComplete="username" value={props.state.user.username}/>
            <Typography paragraph={false} component='div'>
              Your account has MFA enabled. Please scan the QR code below with a TOTP app to complete the setup.
              <ol>
                <li>Install Google Authenticator (or any other TOTP app) on your mobile device.</li>
                <li>Scan the displayed QR code with the TOTP app.</li>
                <li>Enter the current TOTP code from the app when prompted to log in.</li>
              </ol>
            </Typography>
            <Grid container>                  
              <Grid item xs={12} classes={{ root: localCss.qrCode }}>
                <QRCode value={props.state.secret} width={256} height={256}/>
              </Grid>
              <Grid item xs={12}>
                <Field name="token" type="token">
                  {({ input, meta }) => <TextField {...input} onPaste={input.onChange} error={meta.touched && meta.error} helperText={meta.touched && meta.error} fullWidth label="Token" autoComplete="one-time-code" inputMode="numeric" autoFocus/>}
                </Field>
              </Grid>
            </Grid>
          </>
        }
    </AuthenticationForm>
  </>;
}