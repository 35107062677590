import { createReducer } from 'typesafe-actions';
import { setActiveMailbox, loadUserAttributes, setBusy } from './actions';

export interface ActiveMailbox {
  mailbox: string,
  impersonated: boolean,
  impersonatedUser: string
};

export interface UserAttributes {
  email?: string,
  phoneNumber?: string,
  activeMailbox?: ActiveMailbox,
  mailboxes?: Array<string>
};

export const activeMailbox = createReducer<string>('')
  .handleAction(setActiveMailbox.success, (_: unknown, action) => action.payload)
  .handleAction(loadUserAttributes.success, (_: unknown, action) => action.payload.activeMailbox);

export const userAttributes = createReducer<UserAttributes>({})
  .handleAction(loadUserAttributes.success, (_: unknown, { payload }) => {
    return payload;
  });

export const busy = createReducer<boolean>(false)
  .handleAction(setBusy, (_: boolean, { payload } ) => {
    return payload;
  });