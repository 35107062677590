import { Field } from 'react-final-form'
import { FormProps } from './types'
import * as YUP from 'yup'
import { Grid, Link, TextField as MuiTextField } from '@material-ui/core'
import { useValidation } from '../../../misc/validation'
import * as state from '../state'
import { styles } from './styles'
import { isNil } from 'ramda'
import AuthenticationForm from './authentication-form'
import wrapField from './form-field'

export const formValidation = YUP.object({
  name: YUP.string().required(`Please enter your user name`),
  password: YUP.string().required(`Please enter password`)
})

const TextField = wrapField(MuiTextField)

export default function SignIn(props: FormProps<state.SignIn>) {
  const validate = useValidation(formValidation);

  const css = styles();

  return <AuthenticationForm initialValues={{ name: props.state.username }} onSubmit={props.onSubmit} submitText="Submit" title="Sign in" validate={validate}>
    {
      ({ submitting }) => <Grid container>                  
        <Grid item xs={12} classes={{ root: css.item }}>
          <Field name="name">
            {(inputProps) => <TextField {...inputProps} fullWidth label="User name" autoComplete="off" variant="standard" autoFocus={isNil(props.state.username)}/>}
          </Field>
        </Grid>
        <Grid item xs={12} classes={{ root: css.item }}>
          <Field name="password" type="password">
            {(inputProps) => <TextField {...inputProps} fullWidth label="Password" autoComplete="current-password" autoFocus={!isNil(props.state.username)} password-rules="minlength: 8; required: lower; required: upper; required: digit; required: special;"/>}
          </Field>
        </Grid>
        <Grid item xs={12} classes={{ root: css.item }}>
          <Link href="#/login" onClick={props.forgotPassword} color="primary">Forgot password?</Link>
        </Grid>
      </Grid>
    }
  </AuthenticationForm>;
}