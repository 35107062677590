import { makeStyles, Button, Typography } from '@material-ui/core';
import { useMemo } from 'react';
import { List, TextField, Datagrid, FunctionField, Pagination, Filter, TextInput } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useCustomClaims } from '../../../misc/use-custom-claims';
import { setActiveMailbox } from '../../../state/actions';

const defaultFilter = {};

const useStyles = makeStyles((theme) => {
  return {
    activeText: {
      color: theme.palette.success.main
    },
    expandedView: {
      maxWidth: '100%',
      overflow: 'hidden'
    },
    table: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      tableLayout: 'fixed'
    },
    timestampField: {
      width: '150px'
    },
    eventTypeField: {
      width: '150px'
    },
    actionToolbar: {
      marginTop: theme.spacing(1)
    },
    phonenumberCol: {
      width: '180px'
    },
    addressCol: {
      overflow: 'hidden'
    },
    timestampCol: {
      width: '180px'
    },
    editCol: {
      width: '100px'
    }
  };
});

const UsersPagination = props => <Pagination rowsPerPageOptions={[5, 25, 50, 100]} {...props} />;

export const UsersFilter = (props: any) => {
  return <Filter {...props}>
    <TextInput label="Search" source="search" autoComplete="off" resettable alwaysOn/>
  </Filter>;
}

export const ListUsers = (props: any) => {
  const claims = useCustomClaims()
  const styles = useStyles();
  const dispatch = useDispatch();

  const actionColumn = useMemo(() => {
    return (record) => {
      const currentMailbox = record.id === claims.mailbox;
      if (currentMailbox) {
        return <Typography align="center" className={styles.activeText}>Active</Typography>;
      } else {
        return <Button color="primary" variant="text" onClick={() => dispatch(setActiveMailbox.request(record.id))}>Switch</Button>
      }
    };
  }, [dispatch, claims.mailbox]);
  
  return <>
    <List {...props} bulkActionButtons={false} filterDefaultValues={defaultFilter} filters={<UsersFilter/>} perPage={25} pagination={<UsersPagination/>}>
      <Datagrid className={styles.table} size="medium">
        <TextField sortable={true} source="id" label="User" sortBy="id"/>
        <TextField sortable={false} source="customer" label="Customer"/>
        <FunctionField label="Actions" headerClassName={styles.editCol} cellClassName={styles.editCol} render={actionColumn}/>
      </Datagrid>   
    </List>    
  </>;
};