import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    color: theme.palette.text.secondary
  }
}));

const GroupHeader = ({ children, ...rest }) => {
  const classnames = useStyles();
  return <Typography {...rest} variant="h4" className={classnames.header}>{children}</Typography>;
}

export default GroupHeader;