import { useCallback } from 'react';
import { ValidationErrors } from 'final-form'
import * as YUP from 'yup'

export const useValidation = <S extends YUP.Maybe<YUP.AnyObject>, C, D, F extends YUP.Flags>(validation: YUP.ObjectSchema<S, C, D, F>) => {
  const validate = useCallback((data) => {
    try {
      validation.validateSync(data, { abortEarly: false });
      return undefined
    } catch(e: any) {
      const errors: ValidationErrors = {}
      e.inner.forEach((err: YUP.ValidationError) => {
        errors[err.path!] = err.message
      })

      return errors;
    }
  }, [validation]);

  return validate;
}

export type ValidateFunction = ReturnType<typeof useValidation>