import { MenuItem } from '@material-ui/core';
import { SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  input: {
    width: '100%',
    marginBottom: '20px'
  }
});

const AuthSelect = ({ name, getName, ...props }) => {
  const classnames = useStyles();

  if (getName) {
    name = getName(name);
  }
  return <SelectInput variant="filled" margin="none" source={name} {...props} classes={{ input: classnames.input } as any} choices={[
    { id: 'sms_otp', name: 'sms_otp' },
    { id: 'mobileconnect', name: 'mobileconnect' },
  ]}/>;
}

export default AuthSelect;