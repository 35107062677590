import { Auth } from 'aws-amplify'
import { useSnackbar } from 'notistack'
import { isNil } from 'ramda'
import { useCallback, useEffect, useReducer } from 'react'
import { useLogin } from 'react-admin'

export const enum AuthenticationStep {
  LOADING,
  SIGN_IN,
  CHANGE_PASSWORD,
  TOTP_SETUP,
  TOTP_VERIFY,
  RESET_PASSWORD,
  FORGOT_PASSWORD
}

export interface Credentials {
  name: string
  password: string
}

export interface SignIn {
  step: AuthenticationStep.SIGN_IN
  username?: string
}

export interface Loading {
  step: AuthenticationStep.LOADING
}

export interface TotpSetup {
  step: AuthenticationStep.TOTP_SETUP
  secret: string
  user: any
  credentials: Credentials
}

export interface TotpVerify {
  step: AuthenticationStep.TOTP_VERIFY
  user: any
  credentials: Credentials
}

export interface ChangePassword {
  step: AuthenticationStep.CHANGE_PASSWORD
  user: any
}

export interface ResetPassword {
  step: AuthenticationStep.RESET_PASSWORD
  userName: string
}

export interface ForgotPassword {
  step: AuthenticationStep.FORGOT_PASSWORD
}

export type AuthenticationState = Loading | SignIn | TotpSetup | TotpVerify | ChangePassword | ResetPassword | ForgotPassword



export const useAuthenticationState = () => {
  const { enqueueSnackbar } = useSnackbar();
  const login = useLogin();
  const [state, dispatch] = useReducer((state: AuthenticationState, nextState: AuthenticationState): AuthenticationState => {
    return nextState;
  }, { step: AuthenticationStep.LOADING })

  const forgotPassword = useCallback(() => {
    dispatch({
      step: AuthenticationStep.FORGOT_PASSWORD
    })
  }, [dispatch])

  const reportInvalidPassword = useCallback((e) => {
    if (e.code === `InvalidPasswordException`) {
      return {
        password: e.message
      }
    }
    throw e;
  }, []);

  const handleFlowResponse = (user: any, credentials?: any) => {
    if (isNil(user.challengeName)) {
      return login(user.accessToken)
    }
    if(user.challengeName === `NEW_PASSWORD_REQUIRED`) {
      dispatch({
        step: AuthenticationStep.CHANGE_PASSWORD,
        user
      })
    } else if(user.challengeName === `CUSTOM_CHALLENGE` && user.challengeParam?.totpSetup === `true`) {
      dispatch({
        step: AuthenticationStep.TOTP_SETUP,
        secret: user.challengeParam?.secret,
        user,
        credentials
      })
    } 
    else if (user.challengeName === `CUSTOM_CHALLENGE` && user.challengeParam?.totpToken === `true`) {
      dispatch({
        step: AuthenticationStep.TOTP_VERIFY,
        user,
        credentials
      })
    } else {
      login(user.accessToken)
    }
  }

  const submitStep = useCallback((values) => {
    if (state.step === AuthenticationStep.LOADING) {
      return Promise.resolve();
    }
    if (state.step === AuthenticationStep.SIGN_IN) {
      return Auth.signIn(values.name, values.password)
        .then(async (user) => {
          handleFlowResponse(user, values)
        })
        .catch((e) => {
          switch(e.code) {
            case `PasswordResetRequiredException`: 
              return dispatch({
                step: AuthenticationStep.RESET_PASSWORD,
                userName: values.name
              })
            case `UserLambdaValidationException`:
              return enqueueSnackbar(`Error occured during login`, { variant: `error`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
            default:
              enqueueSnackbar(`Invalid username or password`, { variant: `error`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
          }
        })
    }
    if (state.step === AuthenticationStep.TOTP_SETUP || state.step === AuthenticationStep.TOTP_VERIFY) {
      return Auth.sendCustomChallengeAnswer(state.user, values.token)
        .then(async (user) => {
          if (await Auth.currentAuthenticatedUser()) {
            login(user.accessToken)
          }
        })
        .catch(() => {
          enqueueSnackbar(`Wrong TOTP token`, { variant: `error`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })

          return Auth.signIn(state.credentials.name, state.credentials.password).then((user) => {
            dispatch({
              ...state,
              user,
              credentials: values
            })
          })
        })
    }
    if (state.step === AuthenticationStep.CHANGE_PASSWORD) {
      return Auth.completeNewPassword(state.user, values.password)
        .then((user) => handleFlowResponse(user, values))
        .catch(reportInvalidPassword)
        .catch((e) => {
          if (e.message.indexOf(`PASSWORD_CHANGED`) > -1) {
            enqueueSnackbar(
              <>
              Password's been changed successfully.<br/>
              Siging you in...
              </>
            , { variant: `success`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
            return Auth.signIn(state.user.username, values.password)
              .then((user) => handleFlowResponse(user, {
                name: state.user.username,
                password: values.password
              }))
          }
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: `error`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
        })
    }

    if (state.step === AuthenticationStep.RESET_PASSWORD) {
      return Auth.forgotPasswordSubmit(state.userName, values.verificationCode, values.password)
        .then(() => {
          enqueueSnackbar(`Password has been reset`, { variant: `success`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
          dispatch({
            step: AuthenticationStep.SIGN_IN,
            username: state.userName
          })
        })
        .catch(reportInvalidPassword)
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: `error`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
        })
    }

    if (state.step === AuthenticationStep.FORGOT_PASSWORD) {
      return Auth.forgotPassword(values.name)
        .then(() => {
          dispatch({
            step: AuthenticationStep.RESET_PASSWORD,
            userName: values.name
          })

          enqueueSnackbar(`Password reset code has been sent to your email`, { variant: `success`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: `error`, autoHideDuration: 5000, anchorOrigin: { vertical: `top`, horizontal: `center` } })
        })
    }

    return Promise.resolve();
  }, [state, dispatch])

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true
    })
    .then(() => {})
    .catch((e) => {
      dispatch({ step: AuthenticationStep.SIGN_IN })
    })
  }, [])

  return [
    state,
    dispatch,
    submitStep,
    forgotPassword
  ] as const;
}
