import { createTheme } from '@material-ui/core/styles';
import { colors } from '../style';

export const secuMailerTheme = createTheme({
  palette: {
    primary: {
      main: colors.set1.purple
    },
    secondary: {
      main: colors.set1.purpleLight
    },
  }
});