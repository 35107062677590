import { useInput, InputProps } from 'react-admin';
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField, Typography, Unstable_TrapFocus, withStyles } from '@material-ui/core'
import { useCallback, useMemo, useState, KeyboardEvent, MouseEvent, useEffect } from 'react';
import { DeleteForever, Edit, AddSharp, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { FieldInputProps } from 'react-final-form';
import { Form, Field } from 'react-final-form'
import { uniq } from 'ramda';
import { FormApi } from 'final-form';
import { red } from '@material-ui/core/colors';

const DeleteButton = withStyles((theme) => ({
  root: {
    color: red[400],
    '&:hover': {
      color: theme.palette.getContrastText(red[400]),
      backgroundColor: red[600],
    },
    marginLeft: '12px'
  },
}))(IconButton) as any;


const DeleteCheckbox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} icon={<CheckBoxOutlineBlank fontSize="small" />} checkedIcon={<CheckBox fontSize="small" />}/>) as any;

const parse = (value) => {
  if (value instanceof Array) {
    return value;
  }

  return [];
};
const format = (value) => {
  if (value instanceof Array) {
    return value;
  }

  return [];
};

export interface ModalListInputProps extends InputProps {
  label: string
  getName?: (name: string) => string
  checkItem?: (item: string) => boolean
}

const EditItemsDialog = (props: DialogProps & Pick<FieldInputProps<Array<string>, HTMLElement>, 'value' | 'label'> & { onSubmit: (value: Array<string>) => void, onClose: () => void } & Pick<ModalListInputProps, 'checkItem'>) => {
  const [values, setValues] = useState(props.value)
  const [checked, setChecked] = useState<Map<string, boolean>>(new Map())

  const toggleValue = (value) => () => {
    if (checked.get(value)) {
      checked.delete(value)
    } else {
      checked.set(value, true)
    }

    setChecked(new Map(checked))
  }

  useEffect(() => {
    if (props.open) {
      setValues(props.value)
      setChecked(new Map())
    }
  }, [props.open])

  const deleteItems = useCallback(() => {
    setValues(
      values.filter((value) => !checked.has(value))
    )
    setChecked(new Map())
  }, [values, setValues, checked])

  const close = useCallback(() => props.onClose(), [])
  const submit = useCallback(() => props.onSubmit(values), [values])

  const tryAddValue = useCallback(({ newItem }, form: FormApi) => {
    if (props.checkItem ? props.checkItem(newItem) : true) {
      setValues(
        uniq(values.concat(newItem))
      )

      form.reset()
    }
  }, [values, setValues])

  return <Unstable_TrapFocus getDoc={() => window.document} open={props.open} isEnabled={() => props.open}>
    <Dialog {...props} maxWidth="sm" onClose={props.onClose}>
      <DialogTitle>{props.label}</DialogTitle>
      <DialogContent>
        <Grid container direction='column'>
          <Form onSubmit={tryAddValue}>
            {props => (
              <form onSubmit={props.handleSubmit}>
                <Field name="newItem" autoFocus>
                  {({ input }) => <TextField placeholder='Add items' {...input} InputProps={{
                    autoFocus: true,
                    endAdornment: <IconButton onClick={() => props.form.submit()} size="small"><AddSharp/></IconButton>
                  }}/>}
                </Field>
                
              </form>
            )}
          </Form>
          <List>
            {
              values.map((value) => {
                const labelId = `checkbox-list-label-${value}`;
                
                return <ListItem button key={value} dense onClick={toggleValue(value)}>
                  <ListItemIcon style={{ minWidth: '36px' }}>
                    <DeleteCheckbox edge="start" checked={checked.has(value)} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }}/>
                  </ListItemIcon>                  
                  <ListItemText id={value} primary={value} />
                </ListItem>
              })
            }
          </List>  
        </Grid>
      </DialogContent>
      <DialogActions>
        {checked.size > 0 && <DeleteButton size="small" onClick={deleteItems}><DeleteForever/></DeleteButton>}
        <div style={{ flex: '1 0 0' }}/>
        <Button color="default" onClick={close}>Cancel</Button>
        <Button color="primary" onClick={submit}>Save</Button>
      </DialogActions>
    </Dialog>
  </Unstable_TrapFocus>
}

const ModalList = ({ name, ...props }: ModalListInputProps) => {
  
  if (props.getName) {
    name = props.getName(name!);
  }

  const {
    input: { value, onChange },
    meta: { touched, error },
    isRequired
  } = useInput({ ...props, parse, source: name!, format });

  const parsedValue = useMemo(() => parse(value), [value])
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = useCallback(() => setModalOpen(true), [setModalOpen])
  const closeModal = useCallback(() => setModalOpen(false), [setModalOpen])
  const onKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    setModalOpen(true)
  }, [])
  const onMouseDown = useCallback((event: MouseEvent<HTMLInputElement>) => {
    event.preventDefault()
    setModalOpen(true)
  }, [])
  const onSubmit = useCallback((value) => {
    onChange(value)
    closeModal()
  }, [onChange, closeModal])

  const chipElements = <>
    { parsedValue.slice(0, 3).map((val) => <Chip label={val} size='small' style={{ marginRight: '8px' }} key={val}/>) }
    { parsedValue.length > 3 ? <Typography variant='subtitle2' noWrap>+ {parsedValue.length - 3} more</Typography> : false }
  </>
  
  const chips = useMemo(() => {
    return <div style={{ display: 'flex', position: 'relative', top: '12px' }}>
      {chipElements}
    </div>
  }, [value]);

  return <>
    <EditItemsDialog open={modalOpen} value={value} checkItem={props.checkItem} onSubmit={onSubmit} onClose={closeModal} label={props.label}/>
    <TextField fullWidth variant="filled" label={props.label} name={name} required={isRequired} error={error} InputProps={{
      onMouseDown,
      onKeyPress,
      startAdornment: parsedValue.length > 0 ? chips : undefined,
      endAdornment: <IconButton onClick={openModal} size="small"><Edit/></IconButton>
    }}/>
  </>;
}

export default ModalList;