import { gql } from 'graphql-tag';
import { client } from '../client';

const listApi = gql`
  query Users($from: Int, $size: Int, $search: String, $sort: String) {
    users(from: $from, size: $size, search: $search, sort: $sort) {
      items {
        id,
        customer
      },
      size,
      total,
      from
    }
  }
`;

export const getList = async ({ pagination:{ page, perPage }, filter: { search }, sort: { order }}) => {
  const { data: { users: { items, ...rest } } } = await client.query<any>({ 
    query: listApi,
    fetchPolicy: 'no-cache',
    variables: {
      search,
      from: perPage * (page - 1),
      size: perPage,
      sort: order
    }
  });

  return {
    data: items,
    page: rest.from,
    total: rest.total
  };
};

export default {
  getList
}