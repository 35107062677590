import isValidDomain from 'is-valid-domain';
import ModalList from './modal-list'
import { useInput } from 'react-admin';

const checkDomainName = (value) => {
  return isValidDomain(value, { wildcard: true });
}

const DomainModalList = (props) => {
  return <ModalList {...props} checkItem={checkDomainName}/>; 
}

export default DomainModalList;