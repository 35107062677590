import { useQuery } from 'react-admin';

import { SourceViewField } from './source-view-field';
import { DateInput } from 'react-admin-date-inputs';
import DateFnsAdapter from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import { ExpandedViewField } from './expanded-view';

const { List, Datagrid, ChipField, FunctionField, Filter, TextInput, SelectInput, Pagination } = require('react-admin');

const renderTimestamp = ({ source }: any) => source.mail.timestamp.toLocaleString();

const EventPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const dateFns = new DateFnsAdapter();

const defaultFilter = {
  from: dateFns.startOfDay(dateFns.addDays(new Date(), -7)),
  to: dateFns.endOfDay(new Date())
}

const useStyles = makeStyles(() => {
  return {
    expandedView: {
      maxWidth: '100%',
      overflow: 'hidden'
    },
    table: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      tableLayout: 'fixed'
    },
    timestampField: {
      width: '150px'
    },
    eventTypeField: {
      width: '250px'
    }
  };
});

export const EventsFilter = (props: any) => {
  const { data } = useQuery({ 
      type: 'getList',
      resource: 'domains',
      payload: {}
  });

  const domainChoices = data?.map(({ id }) => ({ id, name: id }));

  return <Filter {...props}>
    <DateInput source="from" label="From date" alwaysOn resettable={true}/>
    <DateInput source="to" label="To date" alwaysOn resettable={true}/>
    <TextInput label="Search" source="search" autoComplete="off" resettable alwaysOn/>
    <SelectInput source="domain" choices={domainChoices}/>
  </Filter>;
}

const ExpandedView = ({ record }) => {
  const styles = useStyles();
  return <div className={styles.expandedView}>
    <ExpandedViewField record={record} source="source.mail"/>
  </div>;
};

export const EventsList = (props: any) => {
  const styles = useStyles();
  return <List {...props} bulkActionButtons={false} filterDefaultValues={defaultFilter} filters={<EventsFilter/>} perPage={25} pagination={<EventPagination/>}>
    <Datagrid expand={ExpandedView} className={styles.table} size="medium">
      <FunctionField headerClassName={styles.timestampField} cellClassName={styles.timestampField} label="Timestamp" render={renderTimestamp} sortable={true} sortBy="timestamp"/>
      <ChipField headerClassName={styles.eventTypeField} cellClassName={styles.eventTypeField} source="source.eventType" label="Type" sortable={false}/>
      <SourceViewField source="source.mail" label="Mail headers" sortable={false}/>
    </Datagrid>   
  </List>;
};