import { SelectArrayInput } from 'react-admin';
import { format, startOfDay, addHours } from 'date-fns';
import range from 'ramda/es/range';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  input: {
    width: '100%'
  }
});

const startDate = startOfDay(new Date());
const values = range(0, 24).reduce((acc, hours) => {
  return acc.concat([{
    id: format(addHours(startDate, hours), 'HH:mm'),
    name: format(addHours(startDate, hours), 'hh:mm a')
  }]);
}, [] as { id: string, name: string }[]);

export const TimePeriodSelect = ({ name, getName, ...props }) => {
  const classnames = useStyles();
  if (getName) {
    name = getName(name);
  }
  return <SelectArrayInput source={name} {...props} choices={values} className={classnames.input}/>;
}

export default TimePeriodSelect;