import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Layout, Notification as RaNotification } from 'react-admin';
import { useSelector } from 'react-redux';
import { AppBar } from './app-bar';
import { Menu } from './menu';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 2
  }
}));

const Notification = (...props) => <div>
  <RaNotification {...props}/>
  <div id="ra-modal-root"/>
</div>; 

export const AppLayout = ({ children, ...rest }) => {
  const busy = useSelector((state) => state.busy);
  const classnames = useStyles();

return <>
    <Layout {...rest} notification={Notification} menu={Menu} appBar={AppBar}>
      {children}
    </Layout>
    <Backdrop open={busy} className={classnames.backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  </>;
};
