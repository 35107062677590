import { useInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import reject from 'ramda/es/reject';
import equals from 'ramda/es/equals';

const ChipInput = require(`material-ui-chip-input`).default

const parse = (value) => {
  if (value instanceof Array) {
    return value;
  }

  return [];
};
const format = (value) => {
  if (value instanceof Array) {
    return value;
  }

  return [];
};

const useStyles = makeStyles({
  input: {
    width: '100%',
    marginBottom: '20px'
  }
});

const cancelEnter = (evt) => {
  if (evt.keyCode === 13) {
    evt.preventDefault();
    evt.stopPropagation;
    evt.nativeEvent.stopImmediatePropagation();
  }
};

const TagList = ({ label, name, getName, sourcePath, helperText, checkValue, ...props }) => {
  if (getName) {
    name = getName(name);
  }

  const {
    input: { value, onChange },
    meta: { touched, error },
    isRequired
  } = useInput({ ...props, source: name, parse, format });

  const classnames = useStyles();

  return <div onKeyDown={cancelEnter}>
    <ChipInput 
      error={!!(touched && error)}
      helperText={(touched && error) || helperText}
      required={isRequired}
      onBeforeAdd={checkValue}
      onDelete={(chip) => onChange(reject(equals(chip), value))}
      value={value}
      onAdd={(chip) => onChange(value.concat([chip]))}
      FormHelperTextProps={{variant: 'filled'}}
      label={label}
      className={classnames.input}/>
  </div>;
}

export default TagList;