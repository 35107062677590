import { useEffect, useState } from 'react';

export type AsyncComponent<T> = Promise<React.ComponentType<T>>;

type LoadableProps<WrappedPropsType> = {
  component: AsyncComponent<WrappedPropsType>
} & WrappedPropsType;

export function Loadable<P>({ component, ...rest }: LoadableProps<P>) {
  let [Cmp, setCmp] = useState<React.ComponentType<P>>(null!);
  useEffect(() => {
    component.then((cmp) => {
      setCmp(() => cmp);
    });

    return () => {
      setCmp = () => {};
    };
  }, [component]);
  return Cmp ? <Cmp {...(rest as any)}/> : null;
};