import { Auth } from 'aws-amplify';
import { parse } from 'graphql';

import { setActiveMailbox, loadUserAttributes, setBusy } from '../actions';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import { refreshView } from 'ra-core';

import { client } from '../../components/react-admin/data-provider/client';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoRefreshToken } from 'amazon-cognito-identity-js';

const getActiveMailboxQuery = parse(/* GraphQL */`
  query GetActiveMailbox {
    getActiveMailbox {
      mailbox,
      impersonated,
      customer
    }
  }
`);

const setActiveMailboxMutation = parse(/* GraphQL */`
  mutation SetActiveMailbox($mailbox: String!) {
    setActiveMailbox(mailbox: $mailbox) {
      mailbox,
      impersonated,
      customer
    }
  }
`);

function* updateActiveMailboxSaga() {
  yield takeEvery(setActiveMailbox.request, function* (action) {
    yield put(setBusy(true));

    yield call([client, 'mutate'], {
      mutation: setActiveMailboxMutation,
      optimisticResponse: false,
      variables: {
        mailbox: action.payload
      }
    });

    const refreshSessionPromise = Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
      const refreshToken = new CognitoRefreshToken({
        RefreshToken: user.getSignInUserSession()!.getRefreshToken().getToken()
      })

      return new Promise((resolve, reject) => {
        user.refreshSession(refreshToken, (err, result) => {
          if (err) { return reject(err )}
          resolve(result)
        })
      }).then(() => window.location.reload())
    })

    yield refreshSessionPromise

    const activeMailbox = yield Auth.currentAuthenticatedUser().then(({ signInUserSession }) => {
      return {
        mailbox: signInUserSession.idToken.payload['customer-portal:mailbox'],
        impersonated: signInUserSession.idToken.payload['customer-portal:impersonated'] === 'true',
        customer: signInUserSession.idToken.payload['customer-portal:customer']
      }
    })

    yield put(setActiveMailbox.success(activeMailbox));
    yield call([client, 'resetStore'])
    yield put(setBusy(false));
    
    yield put(refreshView());
  });
}

export const loadActiveMailbox = async () => {
  return client.query({ query: getActiveMailboxQuery, fetchPolicy: 'cache-first'});
}

function *loadUserAttributesSaga() {
  yield takeEvery(loadUserAttributes.request, function* () {
    const loadActiveMailboxCall = call(loadActiveMailbox);

    const currentUserCall = call([Auth, 'currentAuthenticatedUser']);

    const [
      { data: { getActiveMailbox: activeMailbox } },
      user
     ] = yield all([
      loadActiveMailboxCall,
      currentUserCall
     ]);

    yield put(loadUserAttributes.success({
      activeMailbox,
      userName: user.username,
      availableMailboxes: user.attributes['custom:mailbox'].split(',')
    }));
  });
}

export default [
  updateActiveMailboxSaga,
  loadUserAttributesSaga
];